<template>
    <div class="dialog-container">
        <el-dialog
            title="运动人员"
            :visible.sync="showFlag"
            :before-close="closeDialog"
            center
            :close-on-click-modal="false"
            top="2vh"
            width="1250px"
            >
            <el-form ref="form" class="detail-form" label-width="100px">
                <div class="top-info df margin-bottom-20">
                    <div class="top-left">
                        <img
                        v-if="serviceMemberInfo.avatar"
                        :src="serviceMemberInfo.avatar"
                        class="user-icon"
                        >
                        <img v-else class="user-icon" src="@/assets/images/user-icon.png" srcset="">
                    </div>
                    <div class="top-right f-cell">
                        <div class="tr-top df ai">
                            <div class="f-cell elps user-name">{{ serviceMemberInfo.userName }}</div>
                            <div>
                                <el-button type="primary" @click="goToOrder" size="small">预约</el-button>
                                <el-button-group class="margin-left-30">
                                    <el-button plain size="small" @click="goBuyCard" v-if="buttonAuth.includes('venueOperation:memberCard:buyCard')">购卡</el-button>
                                    <el-button plain size="small" @click="openMakeCardDialog">制卡 / 补卡</el-button>
                                    <!-- <el-button plain size="small" icon="el-icon-more"></el-button> -->
                                </el-button-group>
                            </div>
                        </div>
                        <el-row>
                            <el-col :span="8">
                                <el-form-item label="手机号:">
                                    <div class="t-item">{{ serviceMemberInfo.phonenumber }}</div>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="会员卡号:">
                                    <div class="t-item">{{ serviceMemberInfo.cardNumber }}</div>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="会员身份:">
                                    <div class="t-item">{{ serviceMemberInfo.type }}</div>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="性别:">
                                    <div class="t-item">{{ serviceMemberInfo.sexText }}</div>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="所在区域:">
                                    <div class="t-item">
                                        <template v-if="serviceMemberInfo.province">{{ serviceMemberInfo.province }}</template>
                                        <template v-if="serviceMemberInfo.city">{{ serviceMemberInfo.city }}</template>
                                        <template v-if="serviceMemberInfo.area">{{ serviceMemberInfo.area }}</template>
                                    </div>
                                </el-form-item>
                            </el-col>
                            <el-col :span="24">
                                <el-form-item label="常驻地址:">
                                    <div class="t-item">{{ serviceMemberInfo.address }}</div>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </div>
                </div>
            </el-form>
            <div class="list-info">
                <el-tabs v-model="activeName" @tab-click="handleClick">
                    <!-- <el-tab-pane label="场次核销" name="first">
                        <tableOne></tableOne>
                    </el-tab-pane> -->
                    <el-tab-pane label="购卡记录" name="second">
                        <tableTwo ref="tableTwo"></tableTwo>
                    </el-tab-pane>
                    <el-tab-pane label="会员卡签到核销记录" name="third">
                        <tableThree ref="tableThree"></tableThree>
                    </el-tab-pane>
                    <!-- <el-tab-pane label="会员卡使用充值记录" name="four">
                        <tableFour ref="tableFour"></tableFour>
                    </el-tab-pane> -->
                </el-tabs>
            </div>
            <div class="user-card">
                <el-divider></el-divider>
                <div class="card-title">卡列表</div>
                <div class="card-div">
                    <div v-if="userCardList.length === 0" class="no-data">暂无数据</div>
                    <div v-else class="card-list df">
                        <div v-if="userCardList.length > 3" class="jt-div">
                            <el-button type="primary" :disabled="cardPageNum === 1" class="jt-btn jt-btn-left" icon="el-icon-arrow-left" @click="handleCurrentChange(cardPageNum, 1)"></el-button>
                        </div>
                        <div v-for="(item, index) in currentCardList" :key="item.id + '_' + index" class="c-item c-type1"
                        :class="{'c-type1': item.cardType === 1, 'c-type2': item.cardType === 2, 'c-type3': item.cardType === 3}">
                            <div class="c-info">
                                <div class="ci-top df ai">
                                    <div class="ci-title f-cell elps">{{ item.cardName }}</div>
                                    <div class="ci-status"
                                    :class="{'status1': item.state === 1, 'status2': item.state === 2, 'status3': item.state === 3,'status4': item.state === 4}">
                                        <div v-if="item.state === 1">未激活</div>
                                        <div v-if="item.state === 2">正常</div>
                                        <div v-if="item.state === 3">失效</div>
                                        <div v-if="item.state === 4">停用</div>
                                    </div>
                                </div>
                                <div class="ci-bottom">
                                    <div v-if="item.cardType !== 3"  class="cib-item elps">激活日期:
                                        <span v-if="item.activeTime">{{ item.activeTime | formateDate }}</span>
                                        <span v-else>-</span>
                                    </div>
                                    <div v-if="item.cardType !== 3"  class="cib-item elps">到期日期:
                                        <span v-if="item.expireTime">{{ item.expireTime | formateDate }}</span>
                                        <span v-else>以激活日期为准计算</span>
                                    </div>
                                    <div v-if="item.cardType === 2" class="cib-item elps">剩余次数:<span>{{ item.availableTicketQty + item.giveTicketQty }}次</span></div>
                                    <div v-if="item.cardType !== 3"  class="cib-item elps">运动项目:<span>{{ item.sportText }}</span></div>
                                    <div v-if="item.cardType === 3"  class="cib-item elps">充值金额:<span>{{ item.availableMoney }}</span></div>
                                    <div v-if="item.cardType === 3"  class="cib-item elps">赠送金额:<span>{{ item.giveMoney }}</span></div>
                                </div>
                            </div>
                            <div class="c-btn-div">
                                <div class="h-line" style="top: calc(198px / 4);"></div>
                                <div class="h-line" style="top: 99px;"></div>
                                <div class="h-line" style="top: calc(594px / 4);"></div>
                                <div class="s-line" style="left: calc(350px / 4);"></div>
                                <div class="s-line" style="left: 175px;"></div>
                                <div class="s-line" style="left: calc(1050px / 4);"></div>
                                <div class="btn-list df fw">
                                    <div class="btn" @click="openCardDetailDialog(item.id)">详情</div>
                                    <div v-if="buttonAuth.includes('venueOperation:memberCard:refund') && item.availableExitMark === 1 && (item.state === 2 || item.state === 4 || item.state === 1) && item.cardholderMark === 1" class="btn" @click="openCancelCardDialog(item)">退卡</div>
                                    <div v-if="buttonAuth.includes('venueOperation:memberCard:transfer') && item.isTransfer && (item.state === 1 || item.state === 2) && item.cardholderMark === 1 && item.isShowTransfer" class="btn" @click="openTurnCardDialog(item)">转卡</div>
                                    <div v-if="buttonAuth.includes('memberCard:operate:stop') && item.cardType !== 3 && item.pauseLimitQty > 0 && item.state === 2 && item.cardholderMark === 1" class="btn" @click="openStopCardDialog(item)">停卡</div>
                                    <div v-if="buttonAuth.includes('memberCard:operate:giveDay') && item.cardType !== 3 && (item.state === 2 || item.state === 4) && item.cardholderMark === 1" class="btn" @click="openGiveDaysDialog(item)">赠送天数</div>
                                    <div v-if="buttonAuth.includes('memberCard:operate:giveTicket') && item.cardType === 2 && (item.state === 2 || item.state === 4) && item.cardholderMark === 1" class="btn" @click="openGiveTimesDialog(item)">赠送次数</div>
                                    <div class="btn" @click="openOperatingRecordsDialog(item)">操作记录</div>
                                    <div v-if="buttonAuth.includes('memberCard:operate:active') && item.state === 1 && item.cardholderMark === 1" class="btn" @click="openConfirmDialog(item.id, 1)">激活</div>
                                    <div v-if="buttonAuth.includes('memberCard:operate:start') && item.state === 4" class="btn" @click="openConfirmDialog(item.id, 20)">启用</div>
                                    <div v-if="buttonAuth.includes('venueOperation:memberCard:recharge') && item.cardType === 3 && (item.state === 2 || item.state === 4) && item.cardholderMark === 1" class="btn" @click="openRechargeCardDialogDialog(item)">充值</div>
                                    <div v-if="buttonAuth.includes('memberCard:operate:checkin') && item.cardType !== 3 && item.state === 2" class="btn" @click="openConfirmDialog(item.id, 2)">签到</div>
                                    <div v-if="buttonAuth.includes('memberCard:operate:share') && item.state === 2 && item.cardholderMark === 1 && item.shareLimitQty > 0" class="btn" @click="openShareDialog(item)">共享</div>
                                    <div class="btn" v-if="(item.state === 3 || item.state === 2) && item.cardType === 3" @click="openUseRecordDialog(item.id)">使用记录</div>
                                    <div class="btn" v-if="buttonAuth.includes('memberCard:operate:deductDay') && (item.state === 4 || item.state === 2) && (item.cardType === 1 || item.cardType === 2)" @click="openDeductionDayDialog(item)">补扣天数</div>
                                    <div class="btn" v-if="buttonAuth.includes('memberCard:operate:deductTicket') && (item.state === 4 || item.state === 2) && (item.cardType === 2)" @click="openDeductionMumDialog(item)">补扣次数</div>
                                    <div class="btn" v-if="buttonAuth.includes('memberCard:operate:deductAmount') && item.cardType === 3 && item.state === 2" @click="openDeductionMoneyDialog({cardId:item.id, userId:userId})">补扣金额</div>
                                </div>
                            </div>
                        </div>
                        <div v-if="userCardList.length > 3" class="jt-div">
                            <el-button type="primary" :disabled="cardPageNum * 3 >= userCardList.length" class="jt-btn jt-btn-right" icon="el-icon-arrow-right" @click="handleCurrentChange(cardPageNum, 2)"></el-button>
                        </div>
                    </div>
                </div>
            </div>
        </el-dialog>
        <cardDetailDialog ref="cardDetailDialog" :venueBox="venueBox" :merchantBox="merchantBox" />
        <cancelCardDialog ref="cancelCardDialog" @refresh="refreshCardList" />
        <turnCardDialog ref="turnCardDialog" @refresh="refreshCardList" />
        <stopCardDialog ref="stopCardDialog" @refresh="refreshCardList" />
        <giveDaysDialog ref="giveDaysDialog" @refresh="refreshCardList" />
        <giveTimesDialog ref="giveTimesDialog" @refresh="refreshCardList" />
        <operatingRecordsDialog ref="operatingRecordsDialog" />
        <!-- 会员卡使用记录弹窗 -->
        <useRecordDialog ref="useRecordDialog" />
        <rechargeCardDialog ref="rechargeCardDialog" @refresh="refreshCardList" />
        <makeCardDialog ref="makeCardDialog" />
        <!-- 删除确认弹窗 -->
        <confirm-dialog
        ref="confirmDialog"
        :text="confirmDialog.text"
        :show="confirmDialog.visible"
        @sureDialog="sureConfirmDialog"
        @closeDialog="changeConfirmDialog"
        ></confirm-dialog>
        <!-- 补扣金额弹窗 -->
        <deductionMoneyDialog ref="deductionMoneyDialog" @refresh="refreshCardList" />
        <!-- 补扣次数弹窗 -->
        <deductionMumDialog ref="deductionMumDialog" @refresh="refreshCardList" />
        <!-- 补扣天数弹窗 -->
        <deductionDayDialog ref="deductionDayDialog" @refresh="refreshCardList" />
        <!-- 分享弹窗 -->
        <shareDialog ref="shareDialog" @refresh="refreshCardList" />
    </div>
</template>

<script>
import apis from "@/apis";
import { mapState, mapMutations } from "vuex";
import mixin from "@/mixins/dialogMixin";
// import tableOne from "./tableOne.vue";
import tableTwo from "./tableTwo.vue";
import tableThree from "./tableThree.vue";
// import tableFour from "./tableFour.vue";
import cardDetailDialog from "./cardDetailDialog.vue";
import cancelCardDialog from "./cancelCardDialog.vue";
import turnCardDialog from "./turnCardDialog.vue";
import stopCardDialog from "./stopCardDialog.vue";
import giveDaysDialog from "./giveDaysDialog.vue";
import giveTimesDialog from "./giveTimesDialog.vue";
import operatingRecordsDialog from "./operatingRecordsDialog.vue";
import rechargeCardDialog from "./rechargeCardDialog.vue";
import makeCardDialog from "./makeCardDialog.vue";
import confirmDialog from "@/components/confirmDialogNew.vue";
import moment from 'moment'
import useRecordDialog from "./useRecordDialog.vue"; // 使用记录
import deductionMoneyDialog from "./deductionMoneyDialog.vue"; // 补扣金额
import deductionMumDialog from "./deductionMumDialog.vue"; // 补扣次数
import deductionDayDialog from "./deductionDayDialog" // 补扣天数
import shareDialog from "./shareDialog.vue"; // 共享弹窗

export default {
    mixins: [mixin],
    components: {
        // tableOne,
        tableTwo,
        tableThree,
        // tableFour,
        cardDetailDialog,
        cancelCardDialog,
        turnCardDialog,
        stopCardDialog,
        giveDaysDialog,
        giveTimesDialog,
        operatingRecordsDialog,
        rechargeCardDialog,
        makeCardDialog,
        confirmDialog,
        useRecordDialog,
        deductionMoneyDialog,
        deductionMumDialog,
        shareDialog,
        deductionDayDialog,
    },
    data() {
        return {
            showFlag: false,
            form: {},
            activeName: 'second',
            confirmDialog: {
                visible: false,
                type: 1,
                text: '确定激活该会员卡吗?',
            },
            cardPageNum: 1,
            currentCardList: [],
            userCardList: [],
            currentCardId: '',
            apis,
            venueBox: [], // 商户下场馆区域下拉
            merchantBox: [], // 商户下拉
            userId: '',
        };
    },
    computed: {
        ...mapState(['NAME', 'USERNAME', "venueId", 'serviceMemberInfo', 'merchantAccountDetailInfoDTO']),
    },
    filters: {
    formateDate: (value, type = 'YYYY-MM-DD') => {
        if (!value) return ''
        value = value.toString()
        return moment(value).format(type)
        },
    },
    mounted() {
        this.getDropList()
    },
    methods: {
        ...mapMutations(['setServiceMemberInfo']),
        init() {
            this.$refs.form.resetFields();
        },
        /**
         * @function 预约页面跳转到开单
         * @param {Object} row 页面表格行数据
         */
        goToOrder() {
            this.closeDialog()
            this.$router.push({
                name: 'order',
                params: {
                    type: '1',
                    customerPhone: this.serviceMemberInfo.phonenumber,
                },
            })
        },
        // 弹框打开触发
        showDialog(data) {
            console.log(data, 6666)
            this.cardPageNum = 1
            this.showFlag = true
            this.getUserCardList()
            this.userId = data.userId
            this.activeName = 'second'
            this.$nextTick(() => {
                this.$refs.tableTwo.getList()
            });
        },
        handleClick(tab) {
            console.log(tab);
            switch (tab.name) {
                case 'second':
                    this.$refs.tableTwo.getList()
                    break
                case 'third':
                    this.$refs.tableThree.getList()
                    break
                case 'four':
                    this.$refs.tableFour.getList()
                    break
                default:
                    break
            }
        },
        // uploadSuccess(res) {
        //     this.updateUserInfo(res.data.url)
        // },
        // uploadError(res) {
        //     console.log(res)
        // },
        // updateUserInfo(str) {
        //     const postObj = {
        //         userId: this.serviceMemberInfo.userId,
        //         avatar: str,
        //     }
        //     this.$http.post(apis.updateUserInfo2, postObj).then((res) => {
        //         if (res.data.code === 0) {
        //             this.$message.success('操作成功')
        //             const tempObj = {
        //                 ...this.serviceMemberInfo,
        //                 avatar: str,
        //             }
        //             this.setServiceMemberInfo(tempObj)
        //         }
        //     })
        // },
        // 触发index列表页面购卡
        goBuyCard() {
            this.$emit('openBuyCard')
            this.getUserCardList()
        },
        // 卡列表换页
        handleCurrentChange(num, type) {
            const val = type === 1 ? num - 1 : num + 1
            this.$nextTick(() => {
                this.cardPageNum = val
                this.currentCardList = []
                const tempArray = [...this.userCardList]
                for (let i = (val - 1) * 3; i < val * 3; i += 1) {
                    if (tempArray[i]) {
                        this.currentCardList.push(tempArray[i])
                    }
                }
            })
        },
        getDropList() {
            // 获取通行场区下拉取值
            this.$http.get(`${apis.getVenueListByMerchantId}?merchantId=${this.merchantAccountDetailInfoDTO.merchantId}`).then((res) => {
                if (res.data.code === 0) {
                    this.venueBox = res.data.data
                }
            })
            // 获取商户
            this.$http.get(apis.findAllMerchant).then((res) => {
                if (res.data.code === 0) {
                    this.merchantBox = res.data.data
                }
            })
        },
        // 刷新用户卡列表
        refreshCardList() {
            this.getUserCardList()
        },
        // 制卡补卡点击
        openMakeCardDialog() {
            this.$refs.makeCardDialog.showDialog()
        },
        // 卡列表,卡详情点击
        openCardDetailDialog(id) {
            this.$refs.cardDetailDialog.showDialog(id)
        },
        // 卡列表,退卡点击
        openCancelCardDialog(data) {
            this.$refs.cancelCardDialog.showDialog(data)
        },
        // 卡列表,转卡点击
        openTurnCardDialog(data) {
            this.$refs.turnCardDialog.showDialog(data)
        },
        // 卡列表,停卡点击
        openStopCardDialog(data) {
            this.$refs.stopCardDialog.showDialog(data)
        },
        // 卡列表,赠送天数点击
        openGiveDaysDialog(data) {
            this.$refs.giveDaysDialog.showDialog(data)
        },
        // 卡列表,赠送次数点击
        openGiveTimesDialog(data) {
            this.$refs.giveTimesDialog.showDialog(data)
        },
        // 卡列表,操作记录点击
        openOperatingRecordsDialog(data) {
            this.$refs.operatingRecordsDialog.showDialog(data)
        },
        // 卡列表,充值点击
        openRechargeCardDialogDialog(data) {
            console.log(this.cardPageNum)
            const formData = data
            formData.userId = this.serviceMemberInfo.userId
            this.$refs.rechargeCardDialog.showDialog(formData)
        },
        // 卡列表,使用记录点击
        openUseRecordDialog(data) {
            this.$refs.useRecordDialog.showDialog(data)
        },
        // 卡列表,补扣金额点击
        openDeductionMoneyDialog(data) {
            this.$refs.deductionMoneyDialog.showDialog(data)
        },
        // 卡列表,补扣次数点击
        openDeductionMumDialog(data) {
            const datas = data
            datas.userId = this.userId
            this.$refs.deductionMumDialog.showDialog(datas)
        },
        // 卡列表,补扣天数点击
        openDeductionDayDialog(data) {
            const datas = data
            datas.userId = this.userId
            this.$refs.deductionDayDialog.showDialog(datas)
        },
        // 卡列表,共享点击
        openShareDialog(data) {
            const datas = data
            datas.userId = this.userId
            this.$refs.shareDialog.showDialog(datas)
        },
        /**
         * @function 当确认弹窗点击确定的时候触发
         */
        sureConfirmDialog() {
            // 会员卡激活, 会员卡签到
            const postObj = {
                cardId: this.currentCardId,
                operatorName: this.NAME,
                operatorPhone: this.USERNAME,
            }
            let reqUrl = ''
            switch (this.confirmDialog.type) {
                case 1:
                    reqUrl = apis.activateUserCard
                    break
                case 2:
                    postObj.regionId = this.venueId
                    postObj.userId = this.serviceMemberInfo.userId
                    reqUrl = apis.checkinUserCard
                    break
                case 20:
                    postObj.cardId = this.currentCardId
                    reqUrl = apis.userCardStart
                    break
                default:
                    break
            }
            this.$http.post(reqUrl, postObj).then((res) => {
                if (res.data.code === 0) {
                    if (res.data.msg === 'success') {
                        this.$message.success('操作成功')
                    }
                    this.refreshCardList()
                }
                this.changeConfirmDialog(false, this.confirmDialog.type)
            })
        },
        /**
         * @function 改变确认弹窗的状态
         */
        changeConfirmDialog(dialogStatus, type = 1) {
            this.confirmDialog.type = type;
            this.confirmDialog.visible = dialogStatus;
        },
        /**
         * @function 打开确认弹窗
         */
        openConfirmDialog(cardId, type) {
            this.currentCardId = cardId
            switch (type) {
                case 1:
                    this.confirmDialog.text = '确定激活该会员卡吗?'
                    break
                case 2:
                    this.confirmDialog.text = '确定对该会员卡进行签到吗?'
                    break
                case 20:
                    this.confirmDialog.text = '确定提前启用该会员卡吗?'
                    break
                default:
                    break
            }
            this.confirmDialog.type = type
            this.changeConfirmDialog(true, type);
        },
        // 根据会员id获取持卡列表
        getUserCardList() {
            const memberId = this.serviceMemberInfo.userId
            const merchantId = this.merchantAccountDetailInfoDTO.merchantId
            this.$http.get(`${apis.getUserCardList}?userId=${memberId}&merchantId=${merchantId}`).then((res) => {
                if (res.data.code === 0) {
                    this.userCardList = res.data.data.map((item) => {
                        item.sportText = item.sportList ? item.sportList.join(',') : ''
                        return item
                    })
                    const tempArray = [...this.userCardList]
                    const val = this.cardPageNum
                    this.currentCardList = []
                    for (let i = (val - 1) * 3; i < val * 3; i += 1) {
                        if (tempArray[i]) {
                            this.currentCardList.push(tempArray[i])
                        }
                    }
                    // this.currentCardList = tempArray.splice(((this.cardPageNum - 1) * 3), this.cardPageNum * 3)
                    console.log(this.currentCardList, 'this.currentCardList');
                }
            })
        },
        closeDialog() {
            this.init()
            this.$emit('closeDialog', false)
            this.$refs.form.resetFields();
            this.showFlag = false
        },
    },
}
</script>
<style lang='scss' scoped>
.detail-form{
    .user-icon{ border: 1px solid #f1f1f1; width: auto; height: 200px;}
    .el-form-item{ margin-bottom: 0!important;}
    .card-fm{ height: 120px; width:150px;}
    .top-right{ margin-left: 30px;}
    .tr-top{ border-bottom: 1px solid #eeeeee; padding-bottom: 15px; margin-bottom: 10px;
        .user-name{ font-size: 22px; color: #333333; font-weight: bold; margin-right: 30px;}
    }
}
// .list-info{ padding-bottom: 10px;}
.user-card{ padding-bottom: 30px;
    .box-card{ position: relative;}
    .card-title { height: 29px; font-size: 16px;font-weight: bold;color: #000000;line-height: 29px; margin-bottom: 16px;}
    .card-div{  width: 1200px; height: 200px; margin: 0 auto; overflow: hidden;}
    .card-list{  width: 1200px;}
    .jt-div{ padding-top: 4px;}
    .jt-btn{height:190px; padding: 0 10px; font-size: 20px; font-weight:bold;}
    .jt-btn-left{margin-right: 10px;}
    .jt-btn-right{margin-left: 10px;}
    .c-item{ width: 350px; height: 198px; margin:0 10px; background: #f5f6f7; border-radius: 9px;
    position: relative; overflow: hidden;}
    .c-info{ padding: 20px 0 20px 20px;
        .ci-top{ margin-bottom: 10px;}
        .ci-title{ font-weight: bold; margin-right: 10px;font-size: 18px;font-family: YouSheBiaoTiHei;}
        .ci-status{width: 79px; height: 27px; padding-top: 3px; padding-left: 4px; border-radius: 15px 0 0 15px;
            div{ height: 22px; line-height: 22px; border-radius: 12px 0 0 12px; border: 1px solid #ffffff;
            text-align: center; font-size: 14px;}
        }
        .ci-bottom{margin-right: 20px;}
        .cib-item{ margin-bottom: 10px; line-height: 22px;text-shadow: 0px 2px 4px rgba(255, 255, 255, 0.4);
            span{ padding-left:10px; font-weight: bold;}
        }
    }
    .c-btn-div{ position: absolute; left: 0; top: 0; z-index: 2;width: 350px; height: 198px;
    background: rgba(0,0,0,0.9); display: none;}
    .c-item:hover .c-btn-div{ display: block;}
    .h-line{position: absolute; left: 0; z-index: 3;width: 350px; height: 1px; background: rgba(255,255,255,0.1);}
    .s-line{position: absolute; top: 0; z-index: 3;width: 1px; height: 198px; background: rgba(255,255,255,0.1);}
    .btn-list{width: 350px;
        .btn{width: calc(350px / 4); height: calc(198px / 4); text-align: center; line-height: calc(198px / 4); cursor: pointer; color: #8B8885;
        }
        .btn:hover{ font-weight: bold;}
    }

    .c-type1{ background: url('../../../../assets/images/card-bg1.png') center center no-repeat;
        background-size: 100%;
        .ci-title{color: #FFFFFF;}
        .ci-bottom{color: #FFFFFF; }
    }
    .c-type2{ background: url('../../../../assets/images/card-bg2.png') center center no-repeat;
        background-size: 100%;
        .ci-title{color: #000000;}
        .ci-bottom{color: #000000; }
    }
    .c-type3{ background: url('../../../../assets/images/card-bg3.png') center center no-repeat;
        background-size: 100%;
        .ci-title{color: #F7DC9C;}
        .ci-bottom{color: #F7DC9C; }
    }
    .status1{ background: #001E4E;
        div{color: #ffffff;}
    }
    .status2{ background: #28B779;
        div{color: #ffffff;}
    }
    .status3{ background: #D7D7D7;
        div{color: #333333;}
    }
    .status4{ background: #F56C6C;
        div{color: #ffffff;}
    }
}
.pb-20{ padding-bottom: 20px;}
</style>
<style>
 .list-info .el-tabs__nav-wrap::after {
    display: block!important;
    height: 1px;
 }
 .user-card .el-card__header{ padding: 6px 20px;}
</style>
