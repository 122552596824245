<template>
    <div class="dialog-container">
        <el-dialog
            title="会员卡赠送天数"
            :visible.sync="showFlag"
            :before-close="closeDialog"
            center
            :close-on-click-modal="false"
            :append-to-body="true"
            top="10vh"
            width="600px"
            >
            <el-form ref="form" :model="form" class="cancel-form" :rules="rules" label-width="120px">
                <el-form-item label="会员卡名称">
                    {{ cardInfo.cardName }}
                </el-form-item>
                <el-form-item label="有效天数">
                    {{ cardInfo.termDays }}天
                </el-form-item>
                <el-form-item label="现有赠送天数">
                    {{ cardInfo.giveDayQty }}天
                </el-form-item>
                <el-form-item label="剩余天数">
                    {{ cardInfo.availableDayQty + cardInfo.giveDayQty }}天
                </el-form-item>
                <el-form-item label="新增赠送天数" prop="giveDay">
                    <div class="df ai">
                        <el-input-number
                        v-model="form.giveDay"
                        class="width-300"
                        controls-position="right"
                        :precision="0"
                        :step-strictly="true"
                        :min="1"
                        :max="99999"
                        placeholder="请输入赠送天数"
                        ></el-input-number>
                        <div class="margin-left-10">天</div>
                    </div>
                </el-form-item>
                <el-form-item label="赠送原因" prop="remark">
                    <el-input
                    type="textarea"
                    v-model="form.remark"
                    :autosize="{ minRows: 4, maxRows: 7}"
                    placeholder="请输入赠送原因"
                    maxlength="140"
                    show-word-limit></el-input>
                </el-form-item>
            </el-form>
            <template slot="footer">
                <el-button class="footer-confirm" @click="sureEvent">{{$t('sure')}}</el-button>
                <el-button class="footer-cancel" @click="closeDialog">{{$t('cancel')}}</el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import apis from "@/apis";
import { mapState } from "vuex";
import { debounce } from "lodash";

export default {
    data() {
        return {
            showFlag: false,
            form: {
                cardId: '',
                giveDay: null,
                remark: '',
            },
            cardInfo: {},
            rules: {
                giveDay: { required: true, message: '赠送天数不能为空', trigger: ['blur', 'change'] },
            },
        };
    },
    computed: {
        ...mapState(['NAME', 'USERNAME']),
    },
    methods: {
        init() {
            if (this.$refs.form) {
                this.$refs.form.resetFields()
            }
            this.cardInfo = {}
        },
        // 弹框打开触发
        showDialog(data) {
            console.log(data, 111)
            this.init()
            this.form.cardId = data.id
            this.cardInfo = {
                ...data,
            }
            this.showFlag = true
        },
        getDetailInfo(id) {
            this.$http.get(`${apis.getDetailByCardId}?cardId =${id}`).then((res) => {
                if (res.data.code === 0) {
                    this.cardInfo = {
                        ...res.data.rows,
                    }
                }
            })
        },
        // 确定
        sureEvent: debounce(function () {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    const postObj = {
                        ...this.form,
                        operatorName: this.NAME,
                        operatorPhone: this.USERNAME,
                    }
                    this.$http.post(apis.giveDayUserCard, postObj).then((res) => {
                        if (res.data.code === 0) {
                            // this.$message.success('操作成功')
                            this.$emit('refresh')
                            this.closeDialog();
                        }
                    })
                }
            })
        }, 500),
        closeDialog() {
            this.init()
            this.$emit('closeDialog', false)
            this.$refs.form.resetFields();
            this.showFlag = false
        },
    },
}
</script>
<style lang='scss' scoped>
.card-fm{width: 150px; height: 150px;}
</style>
