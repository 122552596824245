const dictList = {
    // 是否
    YES_NO: [
        { value: 1, label: '是' },
        { value: 2, label: '否' },
    ],
    // 支付方式
    REFUND_TYPE: [
        { value: 1, label: '现金' },
        { value: 2, label: '微信' },
    ],
    // 购买渠道
    BUY_TYPE: [
        { value: 1, label: '场馆门店' },
        { value: 2, label: '电话预订' },
        { value: 3, label: '微信小程序' },
    ],
    // 会员卡类型
    CARD_TYPE: [
        { value: 1, label: '期限卡' },
        { value: 2, label: '次卡' },
        { value: 3, label: '储值卡' },
    ],
    // 有效期
    CARD_TERM: {
        D: '天',
        M: '月',
        Y: '年',
    },
    // 激活方式
    ACTIVE_TYPE: [
        { value: 1, label: '自动激活' },
        { value: 2, label: '现场激活' },
        { value: 3, label: '首次使用激活' },
        { value: 4, label: '指定日期激活' },
    ],
    // 可退卡标识
    EXIT_MARK: [
        { value: 1, label: '可退' },
        { value: 2, label: '不可退' },
    ],
    // 卡发布状态
    CARD_STATUS: [
        { value: 1, label: '上架' },
        { value: 2, label: '下架' },
    ],
    // 卡状态
    USER_CARD_STATE: [
        { value: 1, label: '未激活' },
        { value: 2, label: '正常' },
        { value: 3, label: '失效' },
        { value: 4, label: '停用' },
    ],
    // 卡发布状态
    LOCKER_STATUS: [
        { value: 1, label: '正常' },
        { value: 2, label: '逾期' },
    ],
    // 签到类型
    CHECK_IN_TYPE: [
        { value: 1, label: '前台代签' },
    ],
    // 金额可用业务
    AVAILABEL_BUSINESS_TYPE: [
        { value: 1, label: '场地租赁' },
        { value: 2, label: '商品购买' },
        { value: 3, label: '课程购买' },
        { value: 4, label: '器材租赁' },
        { value: 5, label: '损坏赔偿' },
    ],
}

export default dictList
