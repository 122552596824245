<template>
    <div class="dialog-container record-container">
        <el-dialog
            title="会员卡操作记录"
            :visible.sync="showFlag"
            :before-close="closeDialog"
            center
            :close-on-click-modal="false"
            :append-to-body="true"
            custom-class="common-dialog"
            top="2vh"
            width="900px"
            >
            <div class="record-list">
                <el-card v-if="form.id" class="margin-bottom-20">
                    <div slot="header" class="df ai">
                        <div class="card-title">购卡</div>
                        <div class="card-right f-cell df ai text-right">
                            <div class="f-cell"></div>
                            <div class="f-cell elps">操作人：{{ form.operatorName }}</div>
                            <div class="f-cell elps">账号：{{ form.operatorPhone }}</div>
                            <div class="f-cell elps time">{{ form.createTime }}</div>
                        </div>
                    </div>
                    <el-form class="small-form" label-width="120px" label-suffix=":">
                        <el-row>
                            <el-col :span="8">
                                <el-form-item label="会员卡名称">
                                    <div class="t-item">{{ form.name }}</div>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="购卡渠道">
                                    <div class="t-item">{{ valueToText(form.from, buyTypeList, 'value', 'label' ) }}</div>
                                </el-form-item>
                            </el-col>
                            <!-- <el-col :span="8">
                                <el-form-item label="协助业绩占比">
                                    <div class="t-item">50%</div>
                                </el-form-item>
                            </el-col> -->
                            <el-col v-if="cardType === 2" :span="8">
                                <el-form-item label="购买次数">
                                    <div class="t-item">{{ form.availableTicketQty }}</div>
                                </el-form-item>
                            </el-col>
                            <el-col v-if="cardType === 2" :span="8">
                                <el-form-item label="赠送次数">
                                    <div class="t-item">{{ form.giveTicketQty }}次</div>
                                </el-form-item>
                            </el-col>
                            <el-col v-if="cardType === 1" :span="8">
                                <el-form-item label="购买天数">
                                    <div class="t-item">{{ form.availableDayQty }}天</div>
                                </el-form-item>
                            </el-col>
                            <el-col v-if="cardType === 1" :span="8">
                                <el-form-item label="赠送天数">
                                    <div class="t-item">{{ form.giveDayQty }}天</div>
                                </el-form-item>
                            </el-col>
                            <el-col v-if="cardType === 1" :span="8">
                                <el-form-item label="激活日期">
                                    <div class="t-item">{{ form.activeTime }}</div>
                                </el-form-item>
                            </el-col>
                            <el-col v-if="cardType === 2" :span="8">
                                <el-form-item label="有效天数">
                                    <div class="t-item">{{ form.availableDayQty }}天</div>
                                </el-form-item>
                            </el-col>
                            <el-col v-if="cardType !== 3" :span="8">
                                <el-form-item label="到期日期">
                                    <div class="t-item">{{ form.expireTime }}</div>
                                </el-form-item>
                            </el-col>
                            <el-col v-if="cardType === 3" :span="8">
                                <el-form-item label="储值金额">
                                    <div class="t-item">{{ form.money }}元</div>
                                </el-form-item>
                            </el-col>
                            <el-col v-if="cardType === 3" :span="8">
                                <el-form-item label="赠送金额">
                                    <div class="t-item">{{ form.payStorageGiveMoney }}元</div>
                                </el-form-item>
                            </el-col>
                            <el-col v-if="cardType !== 3" :span="8">
                                <el-form-item label="原价">
                                    <div class="t-item">{{ form.money }}元</div>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="售价">
                                    <div class="t-item">{{ form.payableMoney }}元</div>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="实收金额">
                                    <div class="t-item">{{ form.payMoney }}元</div>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="支付方式">
                                    <div class="t-item">{{ form.payTypeText }}</div>
                                </el-form-item>
                            </el-col>
                            <el-col :span="24">
                                <el-form-item label="备注">
                                    <div class="t-item">{{ form.remark }}</div>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                </el-card>
                <el-card v-for="item in flowObj.flowVoList" :key="item.id" class="margin-bottom-20">
                    <div slot="header" class="df ai">
                        <div class="card-title">{{item.type === 3 ? '激活' : '启用'}}</div>
                        <div class="card-right f-cell df ai text-right">
                            <div class="f-cell"></div>
                            <div class="f-cell elps">操作人：{{ item.operatorName }}</div>
                            <div class="f-cell elps">账号：{{ item.operatorPhone}}</div>
                            <div class="f-cell elps time">{{ item.operatorTime }}</div>
                        </div>
                    </div>
                    <el-form class="small-form" label-width="120px" label-suffix=":">
                        <el-row>
                            <el-col :span="8">
                                <el-form-item :label="item.type === 3 ? '激活时间' : '启用时间'">
                                    <div class="t-item">{{item.type === 3 ?  item.activeTime : item.operatorTime}}</div>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8" v-if="item.type === 3">
                                <el-form-item label="激活方式">
                                    <div class="t-item">{{ valueToText(item.activeType, activeTypeList, 'value', 'label' ) }}</div>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                </el-card>
                <el-card v-for="item in flowObj.stopFlowVOList" :key="item.id" class="margin-bottom-20">
                    <div slot="header" class="df ai">
                        <div class="card-title">停卡</div>
                        <div class="card-right f-cell df ai text-right">
                            <div class="f-cell"></div>
                            <div class="f-cell elps">操作人：{{ item.operatorName }}</div>
                            <div class="f-cell elps">账号：{{ item.operatorPhone}}</div>
                            <div class="f-cell elps time">{{ item.createTime }}</div>
                        </div>
                    </div>
                    <el-form class="small-form" label-width="120px" label-suffix=":">
                        <el-row>
                            <el-col :span="8">
                                <el-form-item label="停卡开始日期">
                                    <div class="t-item">{{ item.startTime }}</div>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="停卡结束日期">
                                    <div class="t-item">{{ item.endTime }}</div>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                </el-card>
                <el-card v-for="item in flowObj.refundFlowVOList" :key="item.id" class="margin-bottom-20">
                    <div slot="header" class="df ai">
                        <div class="card-title">退卡</div>
                        <div class="card-right f-cell df ai text-right">
                            <div class="f-cell"></div>
                            <div class="f-cell elps">操作人：{{ item.operatorName }}</div>
                            <div class="f-cell elps">账号：{{ item.operatorPhone}}</div>
                            <div class="f-cell elps time">{{ item.createTime }}</div>
                        </div>
                    </div>
                    <el-form class="small-form" label-width="120px" label-suffix=":">
                        <el-row>
                            <!-- <el-col :span="8">
                                <el-form-item label="剩余天数">
                                    <div class="t-item">{{ item.availableDayQty }}天</div>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="剩余次数">
                                    <div class="t-item">{{ item.availableTicketQty }}次</div>
                                </el-form-item>
                            </el-col> -->
                            <el-col :span="8">
                                <el-form-item label="退款金额">
                                    <div class="t-item">{{ item.refundMoney }}元</div>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="退款方式">
                                    <div class="t-item">{{ valueToText(item.payType, payTypeList, 'value', 'label' ) }}</div>
                                </el-form-item>
                            </el-col>
                            <el-col :span="24">
                                <el-form-item label="退卡原因">
                                    <div class="t-item">{{ item.remark }}</div>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                </el-card>
                <el-card v-for="item in flowObj.transferFlowVOList" :key="item.id" class="margin-bottom-20">
                    <div slot="header" class="df ai">
                        <div class="card-title">转卡</div>
                        <div class="card-right f-cell df ai text-right">
                            <div class="f-cell"></div>
                            <div class="f-cell elps">操作人：{{ item.operatorName }}</div>
                            <div class="f-cell elps">账号：{{ item.operatorPhone}}</div>
                            <div class="f-cell elps time">{{ item.operatorTime }}</div>
                        </div>
                    </div>
                    <el-form class="small-form" label-width="120px" label-suffix=":">
                        <el-row>
                            <el-col :span="8">
                                <el-form-item label="剩余天数" v-if="cardType !== 3">
                                    <div class="t-item">{{ item.availableDayQty }}天</div>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8" v-if="cardType === 2">
                                <el-form-item label="剩余次数">
                                    <div class="t-item">{{ item.availableTicketQty }}次</div>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="转出会员">
                                    <div class="t-item">{{ item.userNameFrom }}</div>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="转入会员">
                                    <div class="t-item">{{ item.userNameTo }}</div>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="转卡手续费">
                                    <div class="t-item">{{ item.serviceCharge }}元</div>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8" v-if="cardType === 3">
                                <el-form-item label="储值金额">
                                    <div class="t-item">{{ item.availableMoney }}元</div>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8" v-if="cardType === 3">
                                <el-form-item label="赠送金额">
                                    <div class="t-item">{{ item.giveMoney }}元</div>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="支付方式">
                                    <div class="t-item">{{ valueToText(item.payType, payTypeList, 'value', 'label' ) }}</div>
                                </el-form-item>
                            </el-col>
                            <el-col :span="24">
                                <el-form-item label="备注">
                                    <div class="t-item">{{ item.remark }}</div>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                </el-card>
                 <el-card v-for="(item, index) in useVOList" :key="index" class="margin-bottom-20">
                    <div slot="header" class="df ai">
                        <div class="card-title">{{ item.giveType === 1 ? '赠送天数' : '赠送次数'}}</div>
                        <div class="card-right f-cell df ai text-right">
                            <div class="f-cell"></div>
                            <div class="f-cell elps">操作人：{{ item.operatorName }}</div>
                            <div class="f-cell elps">账号：{{ item.operatorPhone }}</div>
                            <div class="f-cell elps time">{{ item.createTime }}</div>
                        </div>
                    </div>
                    <el-form class="small-form" label-width="120px" label-suffix=":">
                        <el-row>
                            <el-col :span="8">
                                <el-form-item :label="item.giveType === 1 ? '赠送天数' : '赠送次数'">
                                    <div class="t-item">{{ item.giveQty }}{{ item.giveType === 1 ? '天' : '次'}}</div>
                                </el-form-item>
                            </el-col>
                            <el-col :span="16">
                                <el-form-item label="赠送原因">
                                    <div class="t-item">{{ item.operatorRemarks }}</div>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                </el-card>
                <!-- 补扣天数 -->
                <el-card v-for="item in deductionList" :key="item.id" class="margin-bottom-20">
                    <div slot="header" class="df ai">
                        <div class="card-title">{{item.deductType === 1 ? '补扣天数' : item.deductType === 2 ? '补扣次数' : '补扣金额'}}</div>
                        <div class="card-right f-cell df ai text-right">
                            <div class="f-cell"></div>
                            <div class="f-cell elps">操作人：{{ item.operatorName }}</div>
                            <div class="f-cell elps">账号：{{ item.operatorPhone}}</div>
                            <div class="f-cell elps time">{{ item.createTime }}</div>
                        </div>
                    </div>
                    <el-form class="small-form" label-width="120px" label-suffix=":">
                        <el-row>
                            <el-col :span="12">
                                <el-form-item label="补扣天数" v-if="item.deductType === 1">
                                    <div class="t-item">{{ item.deductQty }}天</div>
                                </el-form-item>
                                <el-form-item label="补扣次数" v-if="item.deductType === 2">
                                    <div class="t-item">{{ item.deductQty }}次</div>
                                </el-form-item>
                                <el-form-item label="补扣金额" v-if="item.deductType === 3">
                                    <div class="t-item">{{ item.deductAmount }}元</div>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="补扣原因">
                                    <div class="t-item">{{ item.operatorRemarks }}</div>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                </el-card>
            </div>
            <template slot="footer">
                <el-button class="footer-confirm" @click="closeDialog">返回</el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import apis from "@/apis";
import { mapState } from "vuex";
import dictList from "@/utils/dictList.js";
import { changeInfo } from "@/utils/index";

export default {
    data() {
        return {
            showFlag: false,
            cardType: '',
            cardId: '',
            form: {},
            flowObj: {
                flowVoList: [],
                refundFlowVOList: [],
                stopFlowVOList: [],
                transferFlowVOList: [],
            },
            useObj: {
                cardStorageUseFlowVO: [],
                refundFlowVOList: [],
                stopFlowVOList: [],
            },
            useVOList: [],
            deductionList: [], // 补扣天数、次数
            cardTypeList: dictList.CARD_TYPE || [],
            cardTermList: dictList.CARD_TERM || {},
            activeTypeList: dictList.ACTIVE_TYPE || [],
            payTypeList: dictList.REFUND_TYPE || [],
            yesNoList: dictList.YES_NO || [],
            cardStatusList: dictList.CARD_STATUS || [],
            buyTypeList: dictList.BUY_TYPE || [],
        };
    },
    computed: {
        ...mapState(['serviceMemberInfo']),
    },
    methods: {
        init() {
            this.form = {}
        },
        // 弹框打开触发
        showDialog(data) {
            console.log(data, 666)
            this.cardId = data.id
            this.cardType = data.cardType
            this.showFlag = true
            this.init()
            this.getBuyCardInfo()
            this.getFlowList()
            this.getUseList()
            this.getdeductionList()
        },
        // 获取购卡信息
        getBuyCardInfo() {
            this.$http.get(`${apis.getOrderByCardId}?cardId=${this.cardId}`).then((res) => {
                if (res.data.code === 0) {
                    this.form = {
                        ...res.data.data,
                    }
                    this.form.cardTypeText = changeInfo(this.form.cardType, this.cardTypeList, 'value', 'label')
                    this.form.cardStatusText = changeInfo(this.form.cardStatus, this.cardStatusList, 'value', 'label')
                    this.form.activeTypeText = changeInfo(this.form.activeType, this.activeTypeList, 'value', 'label')
                    this.form.availableExitMarkText = changeInfo(this.form.availableExitMark, this.yesNoList, 'value', 'label')
                    this.form.payTypeText = changeInfo(this.form.payType, this.payTypeList, 'value', 'label')
                    this.form.shareLimitText = this.form.shareLimitQty ? '是' : '否'
                    this.form.pauseLimitText = this.form.pauseLimitQty ? '是' : '否'
                    this.form.transferLimitText = this.form.transferLimitQty ? '是' : '否'
                    this.form.transferMoreLimitText = this.form.transferLimitQty && this.form.transferLimitQty > 1 ? '是' : '否'
                }
            })
        },
        // 获取卡操作记录(退卡、转卡、停卡、激活等)
        getFlowList() {
            this.$http.get(`${apis.getCardFlowList}?cardId=${this.cardId}`).then((res) => {
                if (res.data.code === 0) {
                    this.flowObj = res.data.rows
                }
            })
        },
        valueToText(value, list, keyValue, keyText) {
            return changeInfo(value, list, keyValue, keyText)
        },
        // 获取卡使用记录(储值卡，次卡，期限卡对应的使用记录，赠送天数，次数等)
        getUseList() {
            this.$http.get(`${apis.getCardUseList}?cardId=${this.cardId}`).then((res) => {
                if (res.data.code === 0) {
                    this.useVOList = res.data.data || []
                   console.log(res.data, 111)
                }
            })
        },
        // 获取卡使用记录(补扣天数，次数)
        getdeductionList() {
            this.$http.get(`${apis.getCardUseDeductList}?cardId=${this.cardId}`).then((res) => {
                if (res.data.code === 0) {
                    this.deductionList = res.data.data || []
                }
            })
        },
        closeDialog() {
            this.init()
            this.$emit('closeDialog', false)
            this.showFlag = false
        },
    },
}
</script>
<style lang='scss' scoped>
    .el-form-item{ margin-bottom: 5px!important;}
    .card-title{ font-size: 16px; font-weight: bold;}
    .card-right{
        div{max-width: 250px; text-align: right; margin-left: 30px;}
        .time{ color: #999999;}
    }
    // .t-item{ font-weight: bold;}
</style>
<style>
 .record-list .el-card__header{ padding: 10px 20px;}
</style>
