<!--
 * @Author: zhongtao
 * @LastEditors: zhongtao
-->
<template>
    <div class="dialog-container">
        <el-dialog
            title="会员卡使用记录"
            :visible.sync="showFlag"
            :before-close="closeDialog"
            center
            :close-on-click-modal="false"
            :append-to-body="true"
            top="2vh"
            width="900px"
            >
            <el-table
                :data="tableData"
                height="500"
                style="width: 100%"
            >
                <el-table-column
                prop="createTime"
                align="left"
                header-align="center"
                min-width="140"
                label="使用时间"
                >
                </el-table-column>
                <el-table-column
                prop="cardName"
                align="center"
                width="135"
                show-overflow-tooltip
                label="会员卡名称"
                >
                </el-table-column>
                <el-table-column
                align="center"
                prop="useTypeName"
                min-width="130"
                show-overflow-tooltip
                label="使用方式"
                >
                </el-table-column>
                <el-table-column
                align="center"
                prop="useMoney"
                min-width="130"
                show-overflow-tooltip
                label="金额（元）"
                >
                    <template slot-scope="scope">
                        <span>{{scope.row.type === 2 || scope.row.type === 3 || scope.row.type === 4 ? '+' : '-'}}{{scope.row.useMoney}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                align="center"
                prop="useBusinessName"
                min-width="130"
                show-overflow-tooltip
                label="使用业务"
                >
                </el-table-column>
            </el-table>
            <div class="pageination-wrapper df ai">
                <div class="f-cell"></div>
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="form.pageNum"
                    :page-sizes="[10, 20, 30, 40]"
                    :page-size="form.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="form.total"
                >
                </el-pagination>
            </div>
            <template slot="footer">
                <el-button class="footer-confirm" @click="closeDialog">关闭</el-button>
            </template>
        </el-dialog>
    </div>
</template>
<script>
import apis from "@/apis";
// import dictList from "@/utils/dictList.js";
// import { changeInfo } from "@/utils/index";

export default {
    data() {
        return {
            tableData: [],
            form: {
                pageNum: 1,
                pageSize: 10,
                total: 0,
            },
            showFlag: false,
            cardId: '',
        };
    },
    methods: {
        showDialog(data) {
            this.cardId = data
            this.showFlag = true
            this.getList()
        },
        // 获取会员卡使用记录列表
        getList() {
            // const postObj = {
            //     ...this.form,
            //     cardId: this.cardId,
            // }
            this.$http.get(`${apis.userCardFindStorageUseRecord}?cardId=${this.cardId}&pageSize=${this.form.pageSize}&pageNum=${this.form.pageNum}`).then((res) => {
                if (res.data.code === 0) {
                    this.tableData = res.data.rows.map((item) => {
                        return item
                    })
                    this.form.total = res.data.total || 0
                }
            })
        },
        handleSizeChange(val) {
            this.form.pageSize = val
            this.getList()
        },
        handleCurrentChange(val) {
            this.form.pageNum = val
            this.getList()
        },
        closeDialog() {
            this.showFlag = false
        },
    },
}
</script>
<style lang='scss' scoped>
.pageination-wrapper{ padding: 15px 0;}
</style>
