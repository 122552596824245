<template>
    <div class="dialog-container">
        <el-dialog
            title="会员卡详情"
            :visible.sync="showFlag"
            :before-close="closeDialog"
            center
            :close-on-click-modal="false"
            top="2vh"
            width="1000px"
            >
            <el-form ref="form" class="detail-form" label-width="120px">
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="发行商户:">
                            <!-- <div class="t-item">{{ form.merchantId }}</div> -->
                            <div class="t-item">{{ valueToText(form.merchantId, merchantBox, 'merchantId', 'merchantName') }}</div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="会员卡类型:">
                            <div class="t-item">{{ form.cardTypeText }}</div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="会员卡名称:">
                            <div class="t-item">{{ form.name }}</div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="激活方式:">
                            <div class="t-item">{{ form.activeTypeText }}</div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="会员卡状态:">
                            <div class="t-item t-status" :class="{'status1': form.cardStatus === 1, 'status2': form.cardStatus === 2}">
                                <span>{{ form.cardStatusText }} </span>
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>
                <div class="line"></div>
                <el-row>
                    <el-col v-if="form.cardType !== 3" :span="8">
                        <el-form-item label="有效天数:">
                            <div class="t-item">{{ form.cardTermText }}</div>
                        </el-form-item>
                    </el-col>
                    <el-col v-if="form.cardType === 2 && form.availableTicketQty" :span="8">
                        <el-form-item label="有效次数:">
                            <div class="t-item">{{ form.availableTicketQty }}次</div>
                        </el-form-item>
                    </el-col>
                    <el-col v-if="form.cardType === 3" :span="8">
                        <el-form-item label="最低储值金额:">
                            <div class="t-item">{{ form.minMoneyLimit || 0 }}元</div>
                        </el-form-item>
                    </el-col>
                    <el-col v-if="form.cardType === 3" :span="8">
                        <el-form-item label="金额可用业务:">
                            <div class="t-item">{{ form.availableToText }}</div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="是否可退卡:">
                            <div class="t-item">{{ form.availableExitMarkText }}</div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="是否可共享:">
                            <div class="t-item">{{ form.shareLimitText }}</div>
                        </el-form-item>
                    </el-col>
                    <el-col v-if="form.shareLimitQty" :span="8">
                        <el-form-item label="最大共享人数:">
                            <div class="t-item">{{ form.shareLimitQty }}人</div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="是否可转卡:">
                            <div class="t-item">{{ form.transferLimitText }}</div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" v-if="form.transferLimitText === '是'">
                        <el-form-item label="是否可多次转卡:">
                            <div class="t-item">{{ form.transferMoreLimitText }}</div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="是否可停卡:">
                            <div class="t-item">{{ form.pauseLimitText }}</div>
                        </el-form-item>
                    </el-col>
                    <el-col v-if="form.pauseLimitQty" :span="8">
                        <el-form-item label="可停卡次数:">
                            <div class="t-item">{{ form.pauseLimitQty }}次</div>
                        </el-form-item>
                    </el-col>
                    <el-col v-if="form.pauseDayLimitQty" :span="8">
                        <el-form-item label="每次停卡天数:">
                            <div class="t-item">{{ form.pauseDayLimitQty }}天</div>
                        </el-form-item>
                    </el-col>
                    <el-col v-if="form.cardType === 3" :span="8">
                        <el-form-item label="提前可预约天数:">
                            <div class="t-item">{{ form.cutOffBufferQty }}天</div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="可通行区域:">
                            <div class="t-item">
                                <span v-for="(item, index) in form.venueDef" :key="index"><span v-if="index > 0">,</span>{{ formatterVenue(item) }}</span>
                            </div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="时段限制:">
                            <div class="t-item">
                                <span v-for="(item, index) in form.periodDef" :key="index">
                                    <el-divider v-if="index > 0" direction="vertical"></el-divider>
                                    <span>{{ getweek(item.day) }} </span>
                                    <span>{{ item.start }} ~ {{ item.end }}</span>
                                </span>
                                <!-- <span>周一、周二、周三、周四、周五  08：00~22：00</span>
                                <el-divider direction="vertical"></el-divider>
                                <span>周六、周日  08：00~18：00</span> -->
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>
                <div class="line"></div>
                 <el-row>
                    <el-col :span="10">
                         <el-form-item label="会员卡封面:">
                            <div class="t-item">
                                <el-image
                                  class="card-fm"
                                  fit="contain"
                                  :src="form.cardImgPath"
                                >
                                    <div slot="error" class="image-slot">
                                        <i class="el-icon-picture-outline"></i>
                                    </div>
                                </el-image>
                            </div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="14">
                        <el-form-item label="售价:">
                            <div class="t-item">{{ form.mallPrice || 0 }}元</div>
                        </el-form-item>
                        <!-- <el-form-item label="会员卡简介:">
                            <div class="t-item elps2">
                                {{ form.introduce }}
                            </div>
                        </el-form-item>
                        <el-form-item label="购卡协议:">
                            <div class="t-item elps2">
                                {{ form.agreement }}
                            </div>
                        </el-form-item> -->
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="会员卡简介:">
                            <div class="t-item" style="width:100%; max-height: 200px; overflow-y:auto;">
                                <div v-html="form.introduce"></div>
                            </div>
                        </el-form-item>
                        <el-form-item label="购卡协议:">
                            <div class="t-item" style="width:100%; max-height: 200px; overflow-y:auto;">
                                <div v-html="form.agreement"></div>
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>
                <div class="line"></div>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="活动名称:">
                            <div class="t-item">{{ form.activityName }}</div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="活动开始时间:">
                            <div class="t-item">{{ form.startTime }}</div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="发行数量:">
                            <div class="t-item">{{ form.inventoryQty }}张</div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="活动结束时间:">
                            <div class="t-item">{{ form.endTime }}</div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="已销售数量:">
                            <div class="t-item">{{ form.sellQty || 0 }}张</div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="优惠方式:">
                            <div class="t-item">{{ form.discounts }}</div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="优惠内容:">
                            <div v-if="form.discountsList" class="t-item">
                                <span v-for="(item, index) in form.discountsList" :key="index">{{ item }}<br /></span>
                                <!-- 赠30天<br />
                                赠2次<br />
                                充1000送100、充2000送200 -->
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <template slot="footer">
                <el-button class="footer-confirm" @click="closeDialog">返回</el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import apis from "@/apis";
import dictList from "@/utils/dictList.js";
import { changeInfo } from "@/utils/index";

export default {
    props: {
        venueBox: {
            required: true,
            type: Array,
            default: () => {
                return []
            },
        },
        merchantBox: {
            required: true,
            type: Array,
            default: () => {
                return []
            },
        },
    },
    data() {
        return {
            showFlag: false,
            form: {},
            cardTypeList: dictList.CARD_TYPE || [],
            cardTermList: dictList.CARD_TERM || {},
            activeTypeList: dictList.ACTIVE_TYPE || [],
            yesNoList: dictList.YES_NO || [],
            cardStatusList: dictList.CARD_STATUS || [],
            availabelBusinessList: dictList.AVAILABEL_BUSINESS_TYPE || [],
            week: [
                { value: "1", text: "周一" },
                { value: "2", text: "周二" },
                { value: "3", text: "周三" },
                { value: "4", text: "周四" },
                { value: "5", text: "周五" },
                { value: "6", text: "周六" },
                { value: "7", text: "周日" },
            ],
        };
    },
    methods: {
        init() {
            this.form = {}
        },
        // 弹框打开触发
        showDialog(id) {
            this.init()
            this.getDetails(id)
            this.showFlag = true
        },
        valueToText(value, list, keyValue, keyText) {
            return changeInfo(value, list, keyValue, keyText)
        },
        formatterVenue(data) {
            let returnData = ''
            if (this.venueBox.length > 0) {
                this.venueBox.forEach((item) => {
                    if (item.id === data) {
                        returnData = item.venueName
                    }
                })
            }
            return returnData
        },
        // 获取星期方法
        getweek(data) {
            const arr = []
            this.week.forEach((item) => {
                data.forEach((el) => {
                    if (el === item.value - 0 || el === item.value) {
                        arr.push(item.text)
                    }
                })
            })
            return arr.join(',')
        },
        getDetails(id) {
            this.$http.get(`${apis.getProductById}?id=${id}`).then((res) => {
                console.log('cardDetails', res);
                if (res.data.code === 0) {
                    this.form = {
                        ...res.data.rows,
                    }
                    this.form.cardTypeText = changeInfo(this.form.cardType, this.cardTypeList, 'value', 'label')
                    this.form.cardStatusText = changeInfo(this.form.cardStatus, this.cardStatusList, 'value', 'label')
                    this.form.activeTypeText = changeInfo(this.form.activeType, this.activeTypeList, 'value', 'label')
                    this.form.availableExitMarkText = changeInfo(this.form.availableExitMark, this.yesNoList, 'value', 'label')
                    this.form.shareLimitText = this.form.shareLimitQty ? '是' : '否'
                    this.form.pauseLimitText = this.form.pauseLimitQty ? '是' : '否'
                    this.form.transferLimitText = this.form.isTransfer ? '是' : '否'
                    this.form.transferMoreLimitText = this.form.transferLimitQty ? '是' : '否'
                    this.form.cardTermText = this.form.cardTerm ? this.form.cardTerm.replace(/\w/g, (val) => {
                        return this.cardTermList[val] || val
                    }) : ''
                    this.form.periodDef = JSON.parse(this.form.availableUseRules[0].periodDef)
                    this.form.regionDef = this.form.availableUseRules[0].regionDef.split(',')
                    this.form.venueDef = this.form.regionDef
                    this.form.discounts = ''
                    this.form.discountsList = []
                    if (this.form.mallPrice && this.form.mallPrice !== this.form.marketPrice) {
                        this.form.discounts += '降价'
                        this.form.discountsList.push(`原价${this.form.marketPrice}元 降价后${this.form.mallPrice}元`)
                    }
                    if (this.form.coupons && this.form.coupons.length > 0) {
                        this.form.discounts += ' 赠送'
                        this.form.coupons.forEach((item) => {
                            if (item.giveDay && item.giveDay > 0) {
                                this.form.discountsList.push(`赠送${item.giveDay}天`)
                            }
                            if (item.giveTicket && item.giveTicket > 0) {
                                this.form.discountsList.push(`赠送${item.giveTicket}次`)
                            }
                            if (item.fullMoney && item.giveMoney > 0) {
                                this.form.discountsList.push(`充${item.fullMoney}送${item.giveMoney}`)
                            }
                        })
                    }
                    if (this.form.cardType === 3) {
                        const tempArr = this.form.availableTo ? this.form.availableTo.split(',') : []
                        const availableToArr = tempArr.map((item) => {
                            let tempStr = ''
                            this.availabelBusinessList.forEach((cItem) => {
                                if (item - 0 === cItem.value) {
                                    tempStr = cItem.label
                                }
                            })
                            return tempStr
                        })
                        this.form.availableToText = availableToArr.length > 0 ? availableToArr.join(',') : '-'
                    }

                }
            })
        },
        closeDialog() {
            this.init()
            this.$emit('closeDialog', false)
            this.showFlag = false
        },
    },
}
</script>
<style lang='scss' scoped>
.detail-form{
    .el-form-item{ margin-bottom: 0!important;}
    .t-item{ font-weight: bold; color: #333333; line-height: 24px; padding-top: 8px;
        .card-fm{ height: 135px; width:240px;}
    }
    .t-item.t-status{ border-radius: 18px; padding: 2px 3px; display: inline-block;
        span{ padding: 2px 10px; border: 1px solid #ffffff; border-radius: 18px; color: #ffffff;}
    }
    .t-item.status1{ background: #28B779;}
    .t-item.status2{ background: #001E4E;}
    .t-item.status3{ background: #D7D7D7;}
    .line{width: 100%;height: 1px; background: #eeeeee; margin: 10px 0;}
}
.pb-20{ padding-bottom: 20px;}
</style>
