<template>
    <div class="dialog-container">
        <el-table
            :data="tableData"
            height="300"
            style="width: 100%"
        >
            <el-table-column
            prop="time"
            align="left"
            header-align="center"
            min-width="120"
            label="购卡时间"
            >
            </el-table-column>
            <el-table-column
            prop="fromText"
            align="center"
            width="105"
            show-overflow-tooltip
            label="购卡渠道"
            >
            </el-table-column>
            <el-table-column
            align="center"
            prop="productName"
            min-width="120"
            show-overflow-tooltip
            label="会员卡名称"
            >
            </el-table-column>
            <el-table-column
            align="center"
            prop="payTypeText"
            min-width="100"
            show-overflow-tooltip
            label="支付方式"
            >
            </el-table-column>
            <el-table-column
            align="center"
            prop="payMoney"
            min-width="100"
            show-overflow-tooltip
            label="支付金额"
            >
            </el-table-column>
            <el-table-column
            align="center"
            prop="telephone"
            min-width="100"
            show-overflow-tooltip
            label="操作人"
            >
                <template slot-scope="scope">
                    {{ scope.row.updateBy || scope.row.createBy }}
                </template>
            </el-table-column>
            <el-table-column
            align="center"
            prop="remark"
            min-width="130"
            show-overflow-tooltip
            label="备注"
            >
            </el-table-column>
        </el-table>
        <div class="pageination-wrapper df ai">
            <div class="f-cell"></div>
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="form.pageNum"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="form.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="form.total"
            >
            </el-pagination>
        </div>
    </div>
</template>
<script>
import apis from "@/apis";
import { mapState } from "vuex";
import dictList from "@/utils/dictList.js";
import { changeInfo } from "@/utils/index";

export default {
    data() {
        return {
            tableData: [],
            form: {
                pageNum: 1,
                pageSize: 10,
                total: 0,
            },
            payTypeList: dictList.REFUND_TYPE || [],
            buyTypeList: dictList.BUY_TYPE || [],
        };
    },
    computed: {
        ...mapState(['serviceMemberInfo', 'merchantAccountDetailInfoDTO']),
    },
    mounted() {
        this.getList()
    },
    methods: {
        // 获取购卡记录列表
        getList() {
            const postObj = {
                ...this.form,
                userId: this.serviceMemberInfo.userId,
                merchantId: this.merchantAccountDetailInfoDTO.merchantId,
            }
            this.$http.post(apis.getBuyCardRecord, postObj).then((res) => {
                if (res.data.code === 0) {
                    this.tableData = res.data.rows.map((item) => {
                        item.payTypeText = changeInfo(item.payType, this.payTypeList, 'value', 'label')
                        item.fromText = changeInfo(item.from, this.buyTypeList, 'value', 'label')
                        return item
                    })
                    this.form.total = res.data.total || 0
                }
            })
        },
        handleSizeChange(val) {
            this.form.pageSize = val
            this.getList()
        },
        handleCurrentChange(val) {
            this.form.pageNum = val
            this.getList()
        },
    },
}
</script>
<style lang='scss' scoped>
.pageination-wrapper{ padding: 15px 0;}
</style>
