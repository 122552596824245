<template>
    <el-drawer
        title="我是标题"
        size="85%"
        :append-to-body="true"
        :visible.sync="showFlag"
        custom-class="drawer-100"
        :wrapperClosable="false"
        :destroy-on-close="true"
    >
        <div slot="title" class="d-title">
            <el-page-header @back="goBack" content="会员购卡"></el-page-header>
        </div>
        <el-container class="d-body">
            <el-main>
                <div class="m-div">
                    <el-form ref="form" :model="form" class="form top-form" label-position="top" label-width="110px" :rules="rules">
                        <div class="info-div">
                            <div class="info-title">会员卡信息</div>
                            <div class="padding0-20">
                                <el-tabs v-model="activeName" stretch @tab-click="tabClick">
                                    <el-tab-pane label="期限卡" name="first"></el-tab-pane>
                                    <el-tab-pane label="次卡" name="second"></el-tab-pane>
                                    <el-tab-pane label="储值卡" name="third"></el-tab-pane>
                                </el-tabs>
                                <el-row style="margin-right:4%;">
                                    <el-col :span="11" :offset="1">
                                        <el-form-item label="会员" prop="userId">
                                            <el-input disabled v-model="form.userName" placeholder="请输入会员"></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <!-- <el-col :span="11" :offset="1">
                                        <el-form-item label="会员卡号" prop="no">
                                            <el-input disabled v-model="form.no" class="w-full" placeholder="请输入会员卡号"></el-input>
                                        </el-form-item>
                                    </el-col> -->
                                    <el-col :span="11" :offset="1">
                                        <el-form-item label="会员卡名称" prop="productId">
                                            <el-select v-model="form.productId" class="w-full" placeholder="请选择会员卡" @change="changeCard">
                                                <el-option
                                                v-for="(item, index) in selectCardList"
                                                :key="item.id + '_' + index"
                                                :label="item.name"
                                                :value="item.id">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="11" :offset="1">
                                        <el-form-item label="激活方式" prop="activeType">
                                            <!-- {{ form.activeTypeText }} -->
                                            <el-input disabled :value="form.activeTypeText"></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col v-if="form.activeType === 4" :span="11" :offset="1">
                                        <el-form-item label="激活日期" prop="activeTime">
                                            <div class="df ai">
                                                <el-date-picker
                                                    v-model="form.activeTime"
                                                    class="f-cell"
                                                    type="date"
                                                    placeholder="选择日期"
                                                    value-format="yyyy-MM-dd"
                                                    @change="changeActiveTime"
                                                >
                                                </el-date-picker>
                                            </div>
                                        </el-form-item>
                                    </el-col>
                                    <template v-if="activeName === 'first'">
                                        <el-col :span="11" :offset="1">
                                            <el-form-item label="购买天数">
                                                <!-- {{ form.availableDayQty }}天 -->
                                                <el-input disabled :value="(form.availableDayQty || 0) + '天'"></el-input>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="11" :offset="1">
                                            <el-form-item label="赠送天数" prop="giveDayQty">
                                                <div class="df ai">
                                                    <el-input-number
                                                        v-model="form.giveDayQty"
                                                        controls-position="right"
                                                        step-strictly
                                                        :precision="0"
                                                        :min="0"
                                                        :max="99999"
                                                        class="f-cell"
                                                        placeholder="请输入赠送天数"
                                                        @change="changeGiveDayQty"
                                                    ></el-input-number>
                                                    <div class="margin-left-10">天</div>
                                                </div>
                                            </el-form-item>
                                        </el-col>
                                    </template>
                                    <template v-if="activeName === 'second'">
                                        <el-col :span="11" :offset="1">
                                            <el-form-item label="有效期">
                                                <!-- {{ form.cardTermText }} -->
                                                <el-input disabled :value="form.cardTermText"></el-input>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="11" :offset="1">
                                            <el-form-item label="购买次数">
                                                <!-- {{ form.availableTicketQty }}次 -->
                                                <el-input disabled :value="(form.availableTicketQty || 0) + '次'"></el-input>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="11" :offset="1">
                                            <el-form-item label="赠送次数">
                                                <div class="df ai">
                                                    <el-input-number
                                                        v-model="form.giveTicketQty"
                                                        controls-position="right"
                                                        step-strictly
                                                        :precision="0"
                                                        :min="0"
                                                        :max="99999"
                                                        class="f-cell"
                                                        placeholder="请输入赠送次数"
                                                    ></el-input-number>
                                                    <div class="margin-left-10">次</div>
                                                </div>
                                            </el-form-item>
                                        </el-col>
                                    </template>
                                    <template v-if="activeName === 'third'">
                                        <el-col :span="11" :offset="1">
                                            <el-form-item label="最低储值金额">
                                                <!-- {{ form.minMoneyLimit }}元 -->
                                                <el-input disabled :value="(form.minMoneyLimit || 0) + '元'"></el-input>
                                            </el-form-item>
                                        </el-col>
                                    </template>
                                    <template v-if="activeName === 'third'">
                                        <el-col :span="11" :offset="1">
                                            <el-form-item label="充值金额" prop="couponId">
                                                <el-select v-model="form.couponId" class="w-full" placeholder="请选择充值金额" @change="changeType">
                                                    <el-option
                                                    v-for="(item, index) in form.coupons"
                                                    :key="item.id + '_' +  index"
                                                    :label="item.name"
                                                    :value="item.id">
                                                    </el-option>
                                                </el-select>
                                            </el-form-item>
                                        </el-col>
                                    </template>
                                    <el-col v-if="activeName !== 'third'" :span="11" :offset="1">
                                        <el-form-item label="到期日期">
                                            <!-- {{ form.dateDue }} -->
                                            <el-input disabled :value="form.dateDue"></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col v-if="form.shareLimitQty" :span="11" :offset="1">
                                        <el-form-item label="共享人数">
                                            <el-input disabled :value="`该类卡最多共享人数${ form.shareLimitQty }人`"></el-input>
                                            <!-- <div class="df ai">
                                                <div class="margin-left-10">
                                                    该类卡最多共享人数{{ form.shareLimitQty }}人
                                                </div>
                                            </div> -->
                                        </el-form-item>
                                    </el-col>
                                    <el-col v-if="form.shareLimitQty" :span="23" :offset="1">
                                        <el-form-item label="其他共享人">
                                            <div class="df ai">
                                                <el-autocomplete ref="selInput" v-model="keyword" :disabled="dynamicTags.length === form.shareLimitQty" class="width-400" :fetch-suggestions="querySearchAsync"
                                                placeholder="请输入会员姓名或电话" suffix-icon="el-icon-search" :debounce="600" @select="handleSelect">
                                                </el-autocomplete>
                                                <div class="margin-left-10">
                                                    <el-tag
                                                    v-for="(tag, index) in dynamicTags"
                                                    :key="tag.userId"
                                                    class="margin-left-10 margin-bottom-10"
                                                    closable
                                                    :disable-transitions="false"
                                                    @close="tagClose(tag, index)">
                                                    {{tag.userName}}
                                                    </el-tag>
                                                </div>
                                            </div>
                                            <!-- <div class="margin-left-10">
                                                如其他共享人非会员，点击快速
                                                <el-button type="text">添加会员</el-button>
                                            </div> -->
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="11" :offset="1">
                                        <el-form-item label="时段限制">
                                            <!-- <div class="lh-small">
                                                <div v-for="(item, index) in form.periodDef" :key="index">
                                                    <span>{{ getweek(item.day) }} </span>
                                                    <span>{{ item.start }} ~ {{ item.end }}</span>
                                                </div>
                                            </div> -->
                                            <el-input disabled :value="form.periodDefText"></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="11" :offset="1">
                                        <el-form-item label="可通行区域">
                                            <!-- <span v-for="(item, index) in form.venueDef" :key="index">{{ formatterVenue(item) }}<span v-if="index !== form.venueDef.length - 1">,</span></span> -->
                                            <el-input readonly :value="form.regionDefText"></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="23" :offset="1">
                                        <el-form-item label="备注" prop="remark">
                                            <el-input type="textarea" v-model="form.remark" :autosize="{ minRows: 3, maxRows: 6}"></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </div>
                        </div>
                        <div class="info-div">
                            <div class="info-title">付款信息</div>
                            <el-row  class="padding0-20">
                                <el-col :span="11" :offset="1">
                                    <el-form-item label="应收金额"  class="mb0">
                                        <!-- {{ form.mallPrice }}元 -->
                                        <el-input disabled :value="amount !== '' ? `${amount}元` : `${ form.mallPrice || 0 }元`"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="11" :offset="1">
                                    <el-form-item label="收款方式" prop="payType">
                                        <el-radio-group v-model="form.payType">
                                            <el-radio-button label="2">现金</el-radio-button>
                                            <el-radio-button label="5">微信</el-radio-button>
                                        </el-radio-group>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="11" :offset="1">
                                    <el-form-item label="收款金额" prop="offlinePayMoney">
                                        <div class="df ai">
                                            <el-input-number
                                                    v-model="form.offlinePayMoney"
                                                    controls-position="right"
                                                    :precision="2"
                                                    :min="0"
                                                    :max="999999"
                                                    class="width-400"
                                                    placeholder="请输入收款金额"
                                                ></el-input-number>
                                            <div class="margin-left-10">元</div>
                                        </div>
                                    </el-form-item>
                                </el-col>
                                <!-- <el-col v-else :span="23" :offset="1">
                                    <el-form-item label="" prop="showOnHp">
                                        <div class="t-item">
                                            <el-image
                                            class="card-fm"
                                            src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg"
                                             >
                                                <div slot="error" class="image-slot">
                                                    <i class="el-icon-picture-outline"></i>
                                                </div>
                                            </el-image>
                                            <div>请会员微信扫码支付</div>
                                        </div>
                                    </el-form-item>
                                </el-col> -->
                            </el-row>
                        </div>
                    </el-form>
                </div>
            </el-main>
            <el-footer>
                <el-button class="footer-confirm" @click="buyCard">{{$t('sure')}}</el-button>
                <el-button class="footer-cancel" @click="goBack">{{$t('cancel')}}</el-button>
            </el-footer>
        </el-container>
        <el-dialog
            title="微信支付"
            :visible.sync="payDialog.show"
            center
            :close-on-click-modal="false"
            :append-to-body="true"
            width="60%">
            <div style="display:flex;width:100%">
                <img :src="payDialog.paymentQRCode" alt="" style="margin: 0 auto;">
            </div>
            <template slot="footer">
                <!-- 如果没特别需求，统一使用mixin里面的关闭方法 -->
                <el-button class="footer-cancel" @click="closePayDialog">{{$t('cancel')}}</el-button>
            </template>
        </el-dialog>
    </el-drawer>
</template>
<script>
import apis from "@/apis";
import { mapState } from "vuex";
import moment from 'moment'
import { groupBy, changeInfo } from "@/utils/index";
import dictList from "@/utils/dictList.js";

export default {
    props: {
        venueBox: {
            required: true,
            type: Array,
            default: () => {
                return []
            },
        },
    },
    data() {
        return {
            showFlag: false,
            dayCardList: [],
            cardList: [],
            ticketCardList: [],
            moneyCardList: [],
            selectCardList: [],
            form: {
                productId: '',
                userId: '',
                venueId: '',
                // no: '',
                userName: '',
                phone: '',
                payType: '2',
                remark: '',
                availableDayQty: 0,
                availableTicketQty: 0,
                minMoneyLimit: 0,
                shareLimitQty: 0,
                mallPrice: 0,
                giveDayQty: 0,
                giveTicketQty: 0,
                offlinePayMoney: 0,
                operatorName: '',
                operatorPhone: '',
                couponId: '',
                coupons: [],
                activeTime: null,
                activeType: '',
                orderFrom: 'venue', // 订单来源 mp:小程序 venue:场馆开单
                endTime: '',
                cardTerm: '',
                cardTermText: '',
                checked: false,
                shareUsers: [],
                regionDef: [],
                venueDef: [],
                periodDef: [],
                dateDue: '', // 到期日期
            },
            activeName: 'first',
            rules: {
               userId: [{ required: true, message: '请选择会员', trigger: ['blur', 'change'] }],
               productId: [{ required: true, message: '请选择会员卡', trigger: ['blur', 'change'] }],
               activeTime: [{ required: true, message: '请选择激活日期', trigger: ['blur', 'change'] }],
               offlinePayMoney: [{ required: true, message: '收款金额不能为空', trigger: ['blur', 'change'] }],
               payType: [{ required: true, message: '请选择收款方式', trigger: ['blur', 'change'] }],
            },
            dynamicTags: [],
            keyword: '',
            timeout: null,
            restaurants: [],
            state: '',
            cardTermList: dictList.CARD_TERM || {},
            activeTypeList: dictList.ACTIVE_TYPE || [],
            week: [
                { value: "1", text: "周一" },
                { value: "2", text: "周二" },
                { value: "3", text: "周三" },
                { value: "4", text: "周四" },
                { value: "5", text: "周五" },
                { value: "6", text: "周六" },
                { value: "7", text: "周日" },
            ],
            amount: '',
            payDialog: {
                show: false,
                paymentQRCode: '',
            },
        };
    },
    computed: {
        ...mapState(['NAME', 'USERNAME', "serviceMemberInfo", "merchantAccountDetailInfoDTO"]),
    },
    mounted() {
    },
    methods: {
        changeType() {
            this.form.coupons.forEach((item) => {
                if (item.id === this.form.couponId) {
                    this.amount = item.fullMoney
                    this.form.offlinePayMoney = item.fullMoney
                }
            });
        },
        init() {
            if (this.$refs.form) {
                this.$refs.form.clearValidate()
            }
            this.form.coupons = []
            this.form = {}
            this.form.userId = this.serviceMemberInfo.userId
            // this.form.no = this.serviceMemberInfo.cardNumber
            this.form.phone = this.serviceMemberInfo.phonenumber
            this.form.userName = this.serviceMemberInfo.userName
            // this.form.payType = '2'
            this.dynamicTags = []
            this.keyword = ''
            this.selectCardList = []
            // 清空应收金额
            this.amount = ''
            if (this.form.payType === '5') {
                this.form.offlinePayMoney = 0.01
            } else {
                this.form.offlinePayMoney = ''
            }
        },
        // tab切换点击
        tabClick(data) {
            this.init()
            switch (data.name) {
                case 'first':
                    this.selectCardList = this.dayCardList
                    break
                case 'second':
                    this.selectCardList = this.ticketCardList
                    break
                case 'third':
                    this.selectCardList = this.moneyCardList
                    break
                default:
                    break
            }
            if (this.$refs.form) {
                this.$refs.form.clearValidate()
            }
        },
        // 弹框打开触发
        showDrawer(data = { cardType: 1 }, list) {
            this.cardList = list
            this.init()
            if (data.id) {
                this.getDetails(data.id)
            }
            if (data.inventoryQty) {
                if (data.inventoryQty > data.sellQty) {
                    this.showFlag = true
                } else {
                    this.showFlag = false
                    this.$message.error('已售空')
                }
            } else {
                this.showFlag = true
            }
            const tempArr = groupBy(this.cardList, (item) => {
                return [item.cardType]
            })
            tempArr.forEach((item) => {
                if (item[0] && item[0].id) {
                    switch (item[0].cardType) {
                        case 1:
                            this.dayCardList = item
                            break
                        case 2:
                            this.ticketCardList = item
                            break
                        case 3:
                            this.moneyCardList = item
                            break
                        default:
                            break
                    }
                }
            })
            switch (data.cardType) {
                case 1:
                    this.activeName = 'first'
                    this.selectCardList = this.dayCardList
                    break
                case 2:
                    this.activeName = 'second'
                    this.selectCardList = this.ticketCardList
                    break
                case 3:
                    this.activeName = 'third'
                    this.selectCardList = this.moneyCardList
                    break
                default:
                    break
            }
        },
        // 商品卡选择改变
        changeCard(val) {
            this.form.coupons = []
            this.form.couponId = ''
            this.getDetails(val)
        },
        // 手动激活改变
        changeActive(val) {
            if (val) {
                this.form.activeTime = ''
            }
        },
        goBack() {
            this.showFlag = false
            this.amount = ''
            this.form.offlinePayMoney = ''
        },
        // 共享人tag删除
        tagClose(tag, index) {
            this.dynamicTags.splice(index, 1)
        },
        handleSelect(item) {
            this.dynamicTags.push(item)
            this.keyword = ''
        },
        // 共享人检索
        querySearchAsync(queryString, cb) {
            this.form.userIdTo = ''
            this.$http.get(`${apis.getUserInfo}?userInfo=${queryString}&merchantId=${this.merchantAccountDetailInfoDTO.merchantId}`).then((res) => {
                if (res.data.code === 0) {
                const dataList = res.data.data.filter((item) => {
                    item.value = item.userName
                    let flag = false
                    this.dynamicTags.forEach((dIitem) => {
                        if (item.userId === dIitem.userId) {
                            flag = true
                        }
                    })
                    return item.userId !== this.serviceMemberInfo.userId && !flag
                })
                cb(dataList);
                }
            })
        },
        formatterVenue(data) {
            let returnData = ''
            if (this.venueBox.length > 0) {
                this.venueBox.forEach((item) => {
                    if (item.id === data) {
                        returnData = item.venueName
                    }
                })
            }
            return returnData
        },
        // 获取星期方法
        getweek(data) {
            const arr = []
            this.week.forEach((item) => {
                data.forEach((el) => {
                    if (el === item.value - 0 || el === item.value) {
                        arr.push(item.text)
                    }
                })
            })
            return arr.join(',')
        },
        // 赠送天数输入改变
        changeGiveDayQty(val) {
            this.getDueDate(this.form.activeType, this.form.availableDayQty, val, this.form.activeTime)
        },
        // 赠送天数输入改变
        changeActiveTime(val) {
            this.getDueDate(this.form.activeType, this.form.availableDayQty, this.form.giveDayQty, val)
        },
        // 计算到期日期 type激活类型  Aday可用天数, zDay赠送天数
        getDueDate(type, Aday, zDay = 0, activeDay = new Date()) {
            const date = activeDay
            const addDay = (Aday - 0) + (zDay - 0)
            if (type === 2 || type === 3) {
                this.form.dateDue = '以激活日期为准计算'
            } else {
                this.$set(this.form, 'dateDue', moment(date).add(addDay, 'days').format("YYYY-MM-DD"))
            }
        },
        getDetails(id) {
            this.$http.get(`${apis.getProductById}?id=${id}`).then((res) => {
                if (res.data.code === 0) {
                    this.form.productId = res.data.rows.id
                    if (res.data.rows.coupons.length > 0) {
                        this.form.giveTicketQty = res.data.rows.coupons[0].giveTicket
                    } else {
                        this.form.giveTicketQty = 0
                    }
                    this.form = {
                        ...this.form,
                        ...res.data.rows,
                    }
                    this.form.activeTypeText = changeInfo(this.form.activeType, this.activeTypeList, 'value', 'label')
                    this.form.cardTermText = this.form.cardTerm ? this.form.cardTerm.replace(/\w/g, (val) => {
                        return this.cardTermList[val] || val
                    }) : ''
                    this.form.periodDef = JSON.parse(this.form.availableUseRules[0].periodDef) || []
                    this.form.regionDef = this.form.availableUseRules[0].regionDef.split(',') || []
                    this.form.venueDef = this.form.regionDef || []
                    this.getDueDate(this.form.activeType, this.form.availableDayQty)

                    this.form.regionDefText = ''
                    let regionDefText = ''
                    this.form.venueDef.forEach((item, index) => {
                        if (index > 0) {
                            regionDefText += ','
                        }
                        regionDefText += this.formatterVenue(item)
                    })
                    this.form.regionDefText = regionDefText

                    this.form.periodDefText = ''
                    let periodDefText = ''
                    this.form.periodDef.forEach((item, index) => {
                        if (index > 0) {
                            periodDefText += '/'
                        }
                        periodDefText += `${this.getweek(item.day)} ${item.start} ~ ${item.end}`
                    })
                    this.form.periodDefText = periodDefText
                    this.form.offlinePayMoney = this.form.mallPrice
                    console.log(this.form, 'this.form');
                }
            })
        },
        buyCard() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    if (this.form.inventoryQty > this.form.sellQty) {
                            const tempObj = {
                            ...this.form,
                        }
                        const postObj = {}
                        if (postObj.activeType === 4) {
                            postObj.activeTime = moment(tempObj.activeTime).format("YYYY-MM-DD HH:mm:ss")
                        } else {
                            postObj.activeTime = null
                        }
                        postObj.activeType = tempObj.activeType
                        postObj.couponId = tempObj.couponId
                        postObj.giveDayQty = tempObj.giveDayQty
                        postObj.giveTicketQty = tempObj.giveTicketQty
                        postObj.offlinePayMoney = tempObj.offlinePayMoney
                        postObj.minMoneyLimit = tempObj.minMoneyLimit || 0
                        postObj.orderFrom = tempObj.orderFrom
                        postObj.payType = tempObj.payType
                        postObj.phone = tempObj.phone
                        postObj.productId = tempObj.productId
                        postObj.remark = tempObj.remark
                        postObj.userId = tempObj.userId
                        postObj.name = tempObj.userName
                        postObj.venueId = tempObj.venueId
                        postObj.orderFrom = 'venue'
                        postObj.operatorName = this.NAME
                        postObj.operatorPhone = this.USERNAME
                        if (tempObj.checked) {
                            postObj.activeType = 2
                        }
                        postObj.shareUsers = this.dynamicTags.map((item) => {
                            item.phone = item.phonenumber
                            item.name = item.userName
                            return item
                        })
                        if (this.form.payType === '5' && this.form.offlinePayMoney === 0) {
                            this.$message.error('收款金额不能等于0')
                            return
                        }
                        this.$http.post(apis.buyCard, postObj).then((res) => {
                            if (res.data.code === 0) {
                                if (res.data.data.paymentQRCode) {
                                    this.payDialog.show = true
                                    this.payDialog.paymentQRCode = res.data.data.paymentQRCode
                                    const wsServer = window.location.protocol === 'http:' ? `ws://${window.location.host}/payapi/websocket/${res.data.data.orderNo}` : `wss://sportscity.one-sports.com/payapi/websocket/${res.data.data.orderNo}`
                                    const websocket = new WebSocket(wsServer) // 创建WebSocket对象
                                    console.log(websocket.readyState) // 查看websocket当前状态
                                    websocket.onmessage = (evt) => {
                                        console.log('推送数据', evt)
                                        const data1 = JSON.parse(evt.data)
                                        if (data1.type === 'pcreservate') {
                                            if (data1.code === '0') {
                                                this.$message.success('操作成功')
                                                this.closePayDialog()
                                                this.$emit('close')
                                                this.closeDialog()
                                            }
                                        }
                                    }
                                } else {
                                    this.$message.success('操作成功')
                                    this.$emit('close')
                                    this.closeDialog()
                                }
                            }
                        })
                    } else {
                        this.$message.error('已售空')
                    }
                }
            })
        },
        closeDialog() {
            this.init()
            this.$emit('closeDialog', false)
            this.showFlag = false
        },
        // 关闭微信支付弹窗
        closePayDialog() {
            this.payDialog.show = false
        },
    },
}
</script>
<style lang='scss' scoped>
.d-body{border-top: 1px solid #dddddd; height: 100%;}
.el-main{padding: 0;  border-bottom: 1px solid #dddddd;}
.el-footer{ text-align: center; line-height: 60px;}
// .m-div{ padding: 20px;}
.form{
    .card-fm{ height: 120px; width:150px;}
}
.pb-20{ padding-bottom: 20px;}
.info-div{ margin-bottom: 10px;
    .info-title{
        height: 20px; line-height: 20px; padding-left:20px; border-left: 4px solid #409EFF;
        font-size: 16px; color: #333333; font-weight: bold; margin: 20px 0;
    }
}
.lh-small{ padding-top: 8px; line-height: 24px;}
</style>
<style lang='scss'>
.drawer-100 .el-drawer__body{height: calc(100% - 80px);}
.info-div .el-tabs__nav-wrap::after {
    display: block!important;
    height: 1px;
 }
.info-div .el-tabs__item {
    padding: 0 10% !important;
}
.info-div .el-tag .el-icon-close {
    line-height: 17px !important;
}
.top-form .el-form-item {
    margin-bottom: 10px!important;
}
.top-form .el-form-item__label {
    padding: 0!important;
}
.top-form .el-input.is-disabled .el-input__inner {
    color: #333333!important;
}
</style>
