<!--
 * @Author: zhongtao
 * @LastEditors: zhongtao
-->
<template>
    <div class="dialog-container-share">
        <el-dialog
        title="共享"
        :visible.sync="showFlag"
        :before-close="closeDialog"
        center
        :close-on-click-modal="false"
        :append-to-body="true"
        width="500px"
        >
            <el-form :model="form" :rules="rules" label-width="110PX" ref="form">
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="可共享人数" prop="shareLimitQty">
                            {{form.shareLimitQty}}人
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="已共享人数" prop="name">
                            <div class="person">{{shareData.length || 0}}人</div>
                            <div class="name" v-for="(item, index) in shareData" :key="index">{{item.name}}</div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="添加共享人" prop="shareUsers">
                            <el-autocomplete ref="selInput" v-model="keyword" class="w-full" :fetch-suggestions="querySearchAsync"
                            placeholder="姓名 / 电话 / 卡号 / 手牌号" suffix-icon="el-icon-search" :debounce="600" @select="handleSelect">
                            </el-autocomplete>
                            <div v-for="(item, index) in form.shareUsers" :key="index" class="searchName">
                                <div>{{item.name}}</div>
                                <div class="close" @click="close(index)">X</div>
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <template slot="footer">
                <el-button class="footer-confirm" @click="sure">确定</el-button>
                <el-button class="footer-cancel" @click="closeDialog">{{$t('cancel')}}</el-button>
            </template>
        </el-dialog>
    </div>
</template>
<script>
import apis from "@/apis";
import { debounce } from "lodash";
import { mapState } from "vuex";

export default {
    data() {
        return {
            form: {
                shareUsers: [],
            },
            showFlag: false,
            rules: {
                shareUsers: { required: true, message: '请添加共享人', trigger: 'blur' },
            },
            data: {},
            shareData: [],
            keyword: '',
        };
    },
    computed: {
        ...mapState(['DROPDOWNBOX', "Language", "venueId", 'serviceMemberInfo', 'merchantAccountDetailInfoDTO']),
    },
    methods: {
        showDialog(data) {
            this.form = { ...data, ...this.form }
            this.showFlag = true
            this.getList()
        },
        // 查找共享人
        querySearchAsync: debounce(function (queryString, cb) {
            if (queryString) {
                this.$http.get(`${apis.getUserInfo}?userInfo=${queryString}&merchantId=${this.merchantAccountDetailInfoDTO.merchantId}`).then((res) => {
                    if (res.data.code === 0) {
                        const dataList = res.data.data.map((item) => {
                        item.value = `${item.userName} / ${item.phonenumber}`
                        return item
                        })
                        cb(dataList);
                    }
                })
            }
        }, 500),
        handleSelect(item) {
            let isBe = true
            if (item.userId === this.serviceMemberInfo.userId) {
                this.$message.error('共享人不能是本人')
                isBe = false
            }
            this.shareData.forEach((el) => {
                if (item.userId === el.userId) {
                    this.$message.error('该共享人已经添加')
                    isBe = false
                }
            })
            this.form.shareUsers.forEach((el) => {
                if (item.userId === el.userId) {
                    this.$message.error('该共享人已经存在')
                    isBe = false
                }
            })
            if (isBe) {
                this.form.shareUsers.push({
                    userId: item.userId,
                    name: item.userName,
                    phone: item.phonenumber,
                })
                this.keyword = ''
                this.$refs.form.validateField("shareUsers")
            }
        },
        // 删除共享人
        close(val) {
            this.form.shareUsers.splice(val, 1)
        },
        getList() {
            this.$http.get(`${apis.userCardFindShareList}?cardId=${this.form.id}`).then((res) => {
                if (res.data.code === 0) {
                    this.shareData = res.data.data
                }
            })
        },
        sure() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    const formData = {}
                    formData.cardId = this.form.id
                    formData.userId = this.form.userId
                    formData.shareUsers = this.form.shareUsers
                    this.$http.post(apis.userCardShare, formData).then((res) => {
                        if (res.data.code === 0) {
                            this.closeDialog()
                            this.$emit('refresh')
                        }
                    })
                }
            })
        },
        closeDialog() {
            this.showFlag = false
            this.$refs.form.resetFields()
            this.form = {
                shareUsers: [],
            }
        },
    },
}
</script>
<style lang='scss' scoped>
.pageination-wrapper{ padding: 15px 0;}
.searchName {
    position: relative;
    display: flex;
    flex-direction: row;
    .close {
        margin-left: 5px;
        color: red;
        cursor: pointer;
    }
}
</style>
