<template>
    <div class="dialog-container">
        <el-dialog
            title="会员卡停卡"
            :visible.sync="showFlag"
            :before-close="closeDialog"
            center
            :close-on-click-modal="false"
            :append-to-body="true"
            top="10vh"
            width="600px"
            >
            <el-form ref="form" :model="form" class="cancel-form" :rules="rules" label-width="120px">
                <el-form-item label="会员卡名称" prop="cardName">
                    <el-input disabled v-model="form.cardName" placeholder=""></el-input>
                </el-form-item>
                <el-form-item label="可停卡次数">
                    {{ cardInfo.pauseLimitQty }}次
                </el-form-item>
                <el-form-item label="已停卡次数">
                    {{ cardInfo.stoppedQty }}次
                </el-form-item>
                <el-form-item label="每次可停卡天数">
                    {{ cardInfo.pauseDayLimitQty }}天
                </el-form-item>
                <el-form-item label="停卡日期" prop="timeArray">
                    <el-date-picker
                    v-model="form.timeArray"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="yyyy-MM-dd"
                    @change="changeDays"
                    :picker-options="startDatePicker"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="本次停卡天数">
                    {{ pauseDayCurrentQty || 0 }} 天
                </el-form-item>
            </el-form>
            <template slot="footer">
                <el-button class="footer-confirm" @click="sureEvent">{{$t('sure')}}</el-button>
                <el-button class="footer-cancel" @click="closeDialog">{{$t('cancel')}}</el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import apis from "@/apis";
import { mapState } from "vuex";
import moment from 'moment'

export default {
    data() {
        return {
            startDatePicker: {
                disabledDate: this.disabledDate,
            },
            showFlag: false,
            form: {
                cardId: '',
                cardName: '',
                beginTime: '',
                endTime: '',
                timeArray: [],
            },
            pauseDayCurrentQty: 0,
            cardInfo: {},
            rules: {
                cardName: { required: true, message: '会员卡名称不能为空', trigger: ['blur', 'change'] },
                timeArray: { required: true, message: '请选择停卡日期', trigger: ['blur', 'change'] },
            },
        };
    },
    computed: {
        ...mapState(['NAME', 'USERNAME']),
    },
    methods: {
        disabledDate: (time) => {
            return time.getTime() < Date.now()
        },
        init() {
            if (this.$refs.form) {
                this.$refs.form.resetFields()
            }
            this.cardInfo = {}
        },
        // 弹框打开触发
        showDialog(data) {
            console.log(data, 777)
            this.init()
            this.form.cardId = data.id
            this.form.cardName = data.cardName
            this.cardInfo = {
                ...data,
            }
            this.showFlag = true
        },
        // 日期选择改变
        changeDays(val) {
            if (val) {
                this.pauseDayCurrentQty = moment(val[1]).diff(moment(val[0]), 'days') + 1
            }
        },
        // 确定
        sureEvent() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    const postObj = {
                        ...this.form,
                        operatorName: this.NAME,
                        operatorPhone: this.USERNAME,
                    }
                    postObj.beginTime = postObj.timeArray[0]
                    postObj.endTime = postObj.timeArray[1]
                    this.$http.post(apis.stopUserCard, postObj).then((res) => {
                        if (res.data.code === 0) {
                            this.$emit('refresh')
                            this.closeDialog();
                        }
                    })
                }
            })
        },
        closeDialog() {
            this.$refs.form.resetFields();
            this.init()
            this.$emit('closeDialog', false)
            this.showFlag = false
            this.pauseDayCurrentQty = 0
        },
    },
    created() {
        console.log(this.startDatePicker);
    },
}
</script>
<style lang='scss' scoped>
.card-fm{width: 150px; height: 150px;}
</style>
