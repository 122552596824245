<!--
 * @Author: zhongtao
 * @LastEditors: zhongtao
-->
<template>
    <div class="dialog-container">
        <el-dialog
        title="补扣次数"
        :visible.sync="showFlag"
        :before-close="closeDialog"
        center
        :close-on-click-modal="false"
        :append-to-body="true"
        width="500px"
        >
            <el-form :model="form" :rules="rules" label-width="110PX" ref="form">
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="会员卡名称" prop="cardName">
                            {{form.cardName}}
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="有效次数" prop="useLimitQty">
                            {{form.useLimitQty || 0}}次
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="现有赠送次数" prop="giveTicketQty">
                            {{form.giveTicketQty || 0}}次
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="剩余次数" prop="availableTicketQty">
                            {{form.availableTicketQty + form.giveTicketQty}}次
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="补扣次数" prop="deductTicket">
                            <el-input-number v-model="form.deductTicket" :min="1" :step="1" :precision="0" :max="999999999"></el-input-number>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="补扣原因" prop="remark">
                            <el-input v-model="form.remark" type="textarea" :rows="3"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <template slot="footer">
                <el-button class="footer-confirm" @click="sure">确定</el-button>
                <el-button class="footer-cancel" @click="closeDialog">{{$t('cancel')}}</el-button>
            </template>
        </el-dialog>
    </div>
</template>
<script>
import apis from "@/apis";
import { mapState } from "vuex";
import { debounce } from "lodash";

export default {
    data() {
        return {
            tableData: [],
            form: {
                deductTicket: '',
                remark: '',
            },
            showFlag: false,
            rules: {
                deductTicket: { required: true, message: '请输入补扣次数', trigger: 'blur' },
                remark: { required: true, message: '请输入补扣原因', trigger: 'blur' },
            },
        };
    },
    computed: {
        ...mapState(['NAME', 'USERNAME', "serviceMemberInfo", "merchantAccountDetailInfoDTO"]),
    },
    methods: {
        showDialog(data) {
            this.form = { ...data, ...this.form }
            this.showFlag = true
        },
        sure: debounce(function () {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    const formData = {}
                    formData.cardId = this.form.id
                    formData.deductTicket = this.form.deductTicket
                    formData.remark = this.form.remark
                    formData.userId = this.form.userId
                    formData.operatorName = this.NAME
                    formData.operatorPhone = this.USERNAME
                    this.$http.post(apis.userCardDeductTicket, formData).then((res) => {
                        if (res.data.code === 0) {
                            this.$message.success('操作成功')
                            this.closeDialog()
                            this.$emit('refresh')
                        }
                    })
                }
            })
        }, 500),
        closeDialog() {
            this.showFlag = false
            this.$refs.form.resetFields()
            this.form = {
                deductTicket: '',
                remark: '',
            }
        },
    },
}
</script>
<style lang='scss' scoped>
.pageination-wrapper{ padding: 15px 0;}
</style>
