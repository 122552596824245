<!--
 * @Author: 陈剑伟
 * @Date: 2020-05-19 10:15:48
<<<<<<< HEAD
 * @LastEditTime: 2021-09-22 15:56:37
 * @LastEditors: Please set LastEditors
=======
 * @LastEditTime: 2021-10-12 14:48:35
 * @LastEditors: zhongtao
>>>>>>> 3b6778e149d25f1a09510b02e38e2162db4d9aaa
 * @Description: In User Settings Edit
 * @FilePath: \onesports-cloud-view-new\src\views\venueMana\course\index.vue
-->
<template>
  <div class="wrap">
    <el-row class="topArea">
      <el-col :span="12">
        <div class="user-info">当前服务人员:
          <span v-if="serviceMemberInfo.userId" @click="openUserInfoDialog(serviceMemberInfo)">
            {{ serviceMemberInfo.userName || '无服务对象' }}
            <template v-if="serviceMemberInfo.type">
              ({{ serviceMemberInfo.type }}身份)
            </template>
          </span>
          <i v-if="serviceMemberInfo.userId" class="el-icon-error clear-icon" @click="clearMemberInfo"></i></div>
      </el-col>
      <el-col :span="12" style="display:flex;align-items: center;">
        <el-button class="addbtn" v-if="buttonAuth.includes('venueOperation:menber:add')" @click="changeAddDialogs(true)">新增会员</el-button>
        <div class="search-div">
          <el-autocomplete ref="selInput" v-model="keyword" class="w-full" :fetch-suggestions="querySearchAsync"
          placeholder="姓名 / 电话 / 卡号 / 手牌号" suffix-icon="el-icon-search" :debounce="600" @select="handleSelect">
          </el-autocomplete>
          <!-- <el-input v-model="keyword" :fetch-suggestions="querySearchAsync" placeholder="姓名 / 电话 / 卡号 / 手牌号" @select="handleSelect">
            <el-button type="proimary" slot="append" icon="el-icon-search" @click="openUserInfoDialog"></el-button>
          </el-input> -->
        </div>
      </el-col>
    </el-row>
    <div class="search-container">
      <el-form ref="form" :model="form" label-width="100px">
        <el-row>
          <el-col :span="6">
            <el-form-item label="适用项目" prop="sportRefs">
              <el-select v-model="form.sportRefs" multiple style="width: 100%">
                <el-option
                  v-for="item in sportList"
                  :key="item.id"
                  :label="item.label"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="会员卡类型" prop="types">
              <el-select v-model="form.type" style="width: 100%">
                <el-option
                  v-for="item in cardTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="会员卡名称" prop="name">
              <el-input
                clearable
                placeholder="请输入会员卡名称"
                v-model="form.name"
                :maxlength="100"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6" class="text-right">
            <el-button
              type="primary"
              @click="searchEvent"
              class="search-button"
              >{{ $t("search") }}</el-button
            >
            <el-button type="primary" @click="cleanForm" class="clean-button">{{
              $t("clean")
            }}</el-button>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="menberCard">
      <noData v-if="tableData.length === 0" />
      <div v-else class="card-list df fw">
        <div v-for="item in tableData" :key="item.id" class="c-item c-type1"
        :class="{'c-type1': item.cardType === 1, 'c-type2': item.cardType === 2, 'c-type3': item.cardType === 3}">
          <div class="ci-top df ai">
            <div class="card-name f-cell elps">{{ item.name }}</div>
            <img v-if="item.giveMark === 1" src="@/assets/images/card-icon.png" class="card-icon" alt="" width="34px" srcset="">
          </div>
          <div class="ci-bottom df">
            <div class="ci-left f-cell">
              <div v-if="item.availableTicketQty" class="cil-item elps">可用次数：<span>{{ item.availableTicketQty }}次</span></div>
              <div v-if="item.availableDayQty" class="cil-item elps">有效期限：<span>{{ item.cardTermText }}</span></div>
              <div class="cil-item elps2">适用运动项目：<span>{{ item.sportDefsText || '无限制' }}</span></div>
            </div>
            <div class="ci-right">
              <div class="cir-price-new">¥{{ item.mallPrice || 0 }}</div>
              <div class="cir-price-old" v-if="item.marketPrice !== item.mallPrice">{{ item.marketPrice }}</div>
              <div class="cir-price-old" v-else></div>
              <div class="cir-btn df ai">
                <div class="f-cell" @click="openDetailDialog(item.id)">详情</div>
                <div class="line"></div>
                <div class="f-cell btn-right" v-if="buttonAuth.includes('venueOperation:memberCard:buyCard')" @click="openBuyCardDrawer(item)">购买</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pageination-wrapper">
      <div></div>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="form.pageNum"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="form.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="form.total"
      >
      </el-pagination>
    </div><!-- 添加/修改弹窗 -->
    <add-dialog ref="addDialog" :show="addDialogs.visible" @closeDialog="changeAddDialogs" @sureDialog="sureDialog"></add-dialog>
    <!-- 详情弹窗 -->
    <detail-dialog ref="detailDialog" :venueBox="venueBox" :merchantBox="merchantBox"></detail-dialog>
    <!-- 用户信息弹窗 -->
    <user-info-dialog
      ref="userInfoDialog"
      :Language="Language"
      @closeDialog="changeAddDialog"
      @openBuyCard="openBuyCardDrawer"
    ></user-info-dialog>
    <!-- 购卡抽屉弹窗 -->
    <buy-card-drawer
      ref="buyCardDrawer"
      :cardList="tableData"
      :venueBox="venueBox"
      @close="buyCardClose"
    ></buy-card-drawer>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import mixin from "@/mixins/index";
import MenberCard from "./menberCard.js";
import apis from "@/apis";
import noData from "@/components/noData.vue";
import detailDialog from "./components/detailDialog.vue";
import userInfoDialog from "./components/userInfoDialog.vue";
import buyCardDrawer from "./components/buyCardDrawer.vue";
import dictList from "@/utils/dictList.js";
import { changeInfo } from "@/utils/index";
import addDialog from "./components/addDialog.vue";
import { debounce } from "lodash";

export default {
  mixins: [mixin],
  components: {
    noData,
    detailDialog,
    buyCardDrawer,
    userInfoDialog,
    addDialog,
  },
  data() {
    return {
      keyword: '',
      form: new MenberCard("form"),
      tableData: [],
      drawerData: [],
      addDialog: {
        visible: false,
      },
      addDialogs: {
        visible: false,
      },
      confirmDialog: {
        visible: false,
        type: 1,
        data: {},
      },
      sportList: [], // 运动项目下拉
      merchantBox: [], // 商户下拉
      venueBox: [], // 商户下场馆区域下拉
      cardTypeList: dictList.CARD_TYPE || [],
      cardTermList: dictList.CARD_TERM || {},
    };
  },
  computed: {
    ...mapState(['DROPDOWNBOX', "Language", "venueId", 'serviceMemberInfo', 'merchantAccountDetailInfoDTO']),
  },
  activated() {
    this.getDropList()
    if (this.$route.params.datas) {
      const data = {
        ...this.$route.params.datas,
        ...{
          userName: this.$route.params.datas.memberName,
          phonenumber: this.$route.params.datas.telephone,
          avatar: this.$route.params.datas.faceImgUrl,
          cardNumber: this.$route.params.datas.idCardNo,
        },
      }
      this.keyword = data.telephone
      this.handleSelect(data)
    }
  },
  methods: {
    /**
     * @function 改变新增弹窗的状态
     * @param {Boolean} dialogStatus 是否展示当前弹窗
     */
    changeAddDialogs(dialogStatus = false) {
      this.$nextTick(() => {
        this.addDialogs.visible = dialogStatus
      })
    },
    /**
     * @function 改变新增弹窗的状态
     * @param {Boolean} data 返回值
     */
    sureDialog(data) {
      this.handleSelect(data)
    },
    ...mapMutations(['setServiceMemberInfo']),
    querySearchAsync: debounce(function (queryString, cb) {
      if (queryString) {
        this.$http.get(`${apis.getUserInfo}?userInfo=${queryString}&merchantId=${this.merchantAccountDetailInfoDTO.merchantId}`).then((res) => {
          if (res.data.code === 0) {
            const dataList = res.data.data.map((item) => {
              item.value = `${item.userName} / ${item.phonenumber}`
              return item
            })
            console.log(dataList, 'dataList');
            cb(dataList);
          }
        })
      }
    }, 500),
    handleSelect(item) {
      const tempObj = {
        ...this.serviceMemberInfo,
        ...item,
      }
      tempObj.sexText = changeInfo(item.sex, this.DROPDOWNBOX.sys_user_sex, 'value', 'label')
      this.setServiceMemberInfo(tempObj)
      this.openUserInfoDialog(item)
      this.keyword = ''
    },
    // 清空当前服务会员信息
    clearMemberInfo() {
      this.setServiceMemberInfo({})
    },
    // 打开详情弹框
    openDetailDialog(id) {
      this.$refs.detailDialog.showDialog(id)
    },
    // 打开购卡抽屉
    openBuyCardDrawer(data) {
        if (!this.serviceMemberInfo.userId) {
          this.$message.warning('请先选择需要服务的会员')
          return
        }
        const postObj = {
          pageNum: 1,
          pageSize: 10000,
          merchantId: this.merchantAccountDetailInfoDTO.merchantId,
        }
        this.$http.post(apis.getProductList, postObj)
          .then((res) => {
            if (res.data.code === 0) {
              console.log(res.data, 'res.data');
              this.drawerData = res.data.rows.map((item) => {
                item.sportDefsText = item.sportList ? item.sportList.join(',') : ''
                item.cardTypeText = changeInfo(item.cardType, this.cardTypeList, 'value', 'label')
                item.cardTermText = item.cardTerm ? item.cardTerm.replace(/\w/g, (val) => {
                  return this.cardTermList[val] || val
                }) : ''
                return item
              });
              this.$refs.buyCardDrawer.showDrawer(data, this.drawerData)
            }
          })
          .catch((err) => {
            this.$message.error(err.msg);
          });
    },
    // 关闭购卡抽屉
    buyCardClose() {
      this.$refs.userInfoDialog.handleClick({ name: 'second' })
      this.$refs.userInfoDialog.getUserCardList()
    },
    // 打开用户信息弹框
    openUserInfoDialog(data) {
      this.$refs.userInfoDialog.showDialog(data)
    },
    getDropList() {
      // 获取商户
      this.$http.get(apis.findAllMerchant).then((res) => {
          if (res.data.code === 0) {
              this.merchantBox = res.data.data
          }
      })
      // 运动项目下拉框
      this.$http.get(apis.dictGetSports).then((res) => {
          if (res.data.code === 0) {
              this.sportList = res.data.data.SPORT_PROJECT
          }
      })
      // 获取通行场区下拉取值
      this.$http.get(`${apis.getVenueListByMerchantId}?merchantId=${this.merchantAccountDetailInfoDTO.merchantId}`).then((res) => {
          if (res.data.code === 0) {
              this.venueBox = res.data.data
          }
      })
    },
    // 获取会员卡列表
    typeFormatter(row, column, cellValue) {
      let name = [];
      this.sportNewList.forEach((item) => {
        if (item.sportsProjectCode === cellValue.toString()) {
          name = item.sportsProjectName;
        }
      });
      return name;
    },
    /**
     * @function 当确认弹窗点击确定的时候触发
     */
    sureConfirmDialog() {
      // 如果一个页面有多个弹窗，视情况决定用if语句还是二元表达式对api进行判断
      this.$http
        .delete(`${apis.coachInfo}?ids=${this.confirmDialog.data.ids}`)
        .then((res) => {
          if (res.data.code === 0) {
            if (this.confirmDialog.type === 2) {
              this.$message.success(`${this.$t("reset_success")}123456`);
            }
            this.$refs.confirmDialog.closeDialog();
          }
        });
    },
    /**
     * @function 改变确认/删除弹窗的状态
     * @param {Boolean} dialogStatus 是否展示当前弹窗
     * @param {Number} type 打开的弹窗类型，1为删除确认
     */
    changeConfirmDialog(dialogStatus, type = 1) {
      this.confirmDialog.type = type;
      this.confirmDialog.visible = dialogStatus;
      if (!dialogStatus) {
        this.confirmDialog.data.ids = "";
        this.searchEvent();
      }
    },
    /**
     * @function 打开删除弹窗
     * @param {Array} id 选中的ID数组
     */
    openDeleteDialog(id) {
      if (id.length === 0) {
        this.$message.error("请先选择数据");
        return;
      }
      this.confirmDialog.data.ids = id.join(",");
      this.changeConfirmDialog(true, 1);
    },
    /**
     * @function 改变新增/修改弹窗的状态
     * @param {Boolean} dialogStatus 是否展示当前弹窗
     * @param {Boolean} isEdit 是否是修改弹窗，如果是则要调用接口，进行回显
     * @param {Object} data 修改回显时，传给后端的值
     */
    changeAddDialog(dialogStatus) {
      if (!dialogStatus) {
        this.searchEvent();
      }
      this.$nextTick(() => {
        this.addDialog.visible = dialogStatus;
      });
    },
    /**
     * @function 搜索方法
     */
    searchData() {
      this.form.merchantId = this.merchantAccountDetailInfoDTO.merchantId
      this.$http.post(apis.getProductList, this.form)
        .then((res) => {
          console.log(res.data, 66666)
          if (res.data.code === 0) {
            this.tableData = res.data.rows.map((item) => {
              item.sportDefsText = item.sportList ? item.sportList.join(',') : ''
              item.cardTypeText = changeInfo(item.cardType, this.cardTypeList, 'value', 'label')
              item.cardTermText = item.cardTerm ? item.cardTerm.replace(/\w/g, (val) => {
                return this.cardTermList[val] || val
              }) : ''
              return item
            });
            this.form.total = res.data.total || 0
          }
        })
        // .catch((err) => {
        //   this.$message.error(err.msg);
        // });
    },
    /**
     * @function 清空搜索栏
     */
    cleanForm() {
      this.form = new MenberCard("form");
      this.$nextTick(() => {
        this.searchEvent();
      });
    },
  },
};
</script>
<style lang="less" scoped>
@import "../../../assets/styles/mixin.scoped.less";
.wrap {
  height: 100%;
  overflow-y: auto;
}
.topArea {
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  margin: 10px 0;
  .user-info{ padding-left: 30px;
    span{ font-weight: bold; padding: 0 5px; cursor: pointer;}
    .clear-icon{ color: #F56C6C; margin-left: 10px; cursor: pointer;}
  }
  .addbtn {
    min-width: 90px;
    height: 30px;
    line-height: 4px;
    color: #fff;
    background: #439BFF;
  }
  .search-div{margin: 0 30px; width: 400px; float: right;}
}
.search-wrapper {
  width: 100%;
}
.pageination-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px;
}
.menberCard {
  // display: flex;
  // flex-direction: row;
  padding: 15px;
  height: calc(100% - 400px);
  // min-height: 400px;
  background-color: #fff;
  overflow-y: auto;
}
.c-item{
  width: 374px; height: 210px; border-radius: 10px; overflow: hidden; box-shadow: 0 2px 10px
   0 rgba(0,0,0,0.3); margin-right: 20px; margin-bottom: 15px;
  .ci-top{ height: 34px; margin: 20px 25px;
    .card-name{ font-family: YouSheBiaoTiHei; font-size: 25px; line-height: 25px; font-weight: bold;}
  }
  .ci-bottom{margin: 0 20px 20px 25px;
    .ci-left{margin-right: 10px;}
    .cil-item{ font-size: 14px; line-height: 22px; margin-bottom: 10px;text-shadow: 0px 2px 4px rgba(255, 255, 255, 0.4);
      span{ font-size: 14px; font-weight: bold;}
    }
    .ci-right{ width: 115px;}
    .cir-price-new{font-size: 30px;height: 24px;font-weight: bold;line-height: 24px; margin-bottom: 25px;
    text-align: right;text-shadow: 0px 2px 4px rgba(255, 255, 255, 0.4);}
    .cir-price-old{font-size: 16px;height: 12px;line-height: 12px; margin-bottom: 20px;
    text-align: right; text-decoration: line-through;}
    .cir-btn{width: 115px; height: 32px; line-height: 32px; border-radius: 16px; font-size: 14px;
    color: #ffffff;text-align: center; cursor: pointer;
      .line{ height: 16px; width: 1px; background: #ffffff;}
    }
  }
}
.c-type1{ background: url('../../..//assets/images/card-bg1.png') center center no-repeat;
  .card-name{color: #FFFFFF;}
  .cil-item{color: #FFFFFF; }
  .ci-right{color: #FFFFFF;}
  .cir-btn{background: #4882EA;}
}
.c-type2{ background: url('../../..//assets/images/card-bg2.png') center center no-repeat;
  .card-name{color: #363636;}
  .cil-item{color: #363636; }
  .ci-right{color: #363636;}
  .cir-btn{background: #B6B7BD;}
}
.c-type3{ background: url('../../..//assets/images/card-bg3.png') center center no-repeat;
  .card-name{color: #F7DC9C;}
  .cil-item{color: #F7DC9C; }
  .ci-right{color: #F7DC9C;}
  .cir-btn{background: #222222;}}
</style>
<style lang="less">
@import "../../../assets/styles/mixin.less";
.search-div .el-input-group__append{
  background: #409EFF!important;
  border: 1px solid #409EFF!important;
  color: #ffffff;
}
</style>
