<!--
 * @Author: zhongtao
 * @LastEditors: zhongtao
-->
<template>
    <div class="dialog-container">
        <el-dialog
        title="扣费"
        :visible.sync="showFlag"
        :before-close="closeDialog"
        center
        :close-on-click-modal="false"
        :append-to-body="true"
        width="500px"
        >
            <el-form :model="form" :rules="rules" label-width="100PX" ref="form">
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="扣费金额" prop="deductAmount">
                            <el-input-number v-model="form.deductAmount" :min="0" :precision="2" :max="999999999"></el-input-number>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="使用业务" prop="type">
                            <el-select v-model="form.type" placeholder="请选择业务类型">
                                <el-option label="场地预定" value="1"></el-option>
                                <el-option label="购买票务" value="2"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="扣费原因" prop="reason">
                            <el-input v-model="form.reason" type="textarea" :rows="3"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <template slot="footer">
                <el-button class="footer-confirm" @click="sure">确定</el-button>
                <el-button class="footer-cancel" @click="closeDialog">{{$t('cancel')}}</el-button>
            </template>
        </el-dialog>
    </div>
</template>
<script>
import apis from "@/apis";
import { mapState } from "vuex";
import { debounce } from "lodash";

export default {
    data() {
        return {
            tableData: [],
            form: {
                deductAmount: '',
                type: '',
                reason: '',
            },
            showFlag: false,
            rules: {
                deductAmount: { required: true, message: '请输入扣费金额', trigger: 'blur' },
                type: { required: true, message: '请选择使用业务', trigger: 'change' },
                reason: { required: true, message: '请输入扣费原因', trigger: 'blur' },
            },
        };
    },
    computed: {
        ...mapState(['NAME', 'USERNAME', "serviceMemberInfo", "merchantAccountDetailInfoDTO"]),
    },
    methods: {
        init() {
            this.$refs.form.resetFields();
        },
        showDialog(data) {
            console.log(data, 'data')
            this.showFlag = true
            this.form.cardId = data.cardId
            this.form.userId = data.userId
        },
        sure: debounce(function () {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    const formData = { ...this.form }
                    formData.operatorName = this.NAME
                    formData.operatorPhone = this.USERNAME
                    this.$http.post(apis.deductionMoney, formData).then((res) => {
                        if (res.data.code === 0) {
                            this.closeDialog()
                            this.$emit('refresh')
                        }
                    })
                }
            })
        }, 500),
        closeDialog() {
            this.showFlag = false
            this.init()
        },
    },
}
</script>
<style lang='scss' scoped>
.pageination-wrapper{ padding: 15px 0;}
</style>
