<template>
    <div class="dialog-container">
        <el-dialog
            title="会员卡转卡"
            :visible.sync="showFlag"
            :before-close="closeDialog"
            center
            :close-on-click-modal="false"
            :append-to-body="true"
            top="10vh"
            width="600px"
            >
            <el-form ref="form" :model="form" class="cancel-form" :rules="rules" label-width="100px">
                <el-form-item label="转入会员" prop="userIdTo">
                    <el-autocomplete ref="selInput" v-model="keyword" class="w-full" :fetch-suggestions="querySearchAsync"
                    placeholder="请输入会员姓名或电话" suffix-icon="el-icon-search" :debounce="600" @select="handleSelect">
                    </el-autocomplete>
                </el-form-item>
                <el-form-item label="转卡手续费" prop="serviceCharge">
                    <div class="df ai">
                        <el-input-number
                        v-model="form.serviceCharge"
                        class="width-300"
                        controls-position="right"
                        :precision="2"
                        :min="0"
                        :max="9999999"
                        placeholder="请输入转卡手续费"
                        ></el-input-number>
                        <div class="margin-left-10">元</div>
                    </div>
                </el-form-item>
                <el-form-item label="收款方式" prop="payType">
                    <el-radio-group v-model="form.payType">
                        <!-- <el-radio-button label="微信"></el-radio-button> -->
                        <el-radio-button :label="1">现金</el-radio-button>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="收款金额:" prop="payMoney">
                    <div class="df ai">
                        <el-input-number
                        v-model="form.payMoney"
                        class="width-300"
                        controls-position="right"
                        :precision="2"
                        :min="0"
                        :max="9999999"
                        placeholder="请输入收款金额"
                        ></el-input-number>
                        <div class="margin-left-10">元</div>
                    </div>
                </el-form-item>
                <!-- <el-form-item label="" prop="showOnHp">
                    <div class="t-item">
                        <el-image
                        class="card-fm"
                        fit="contain"
                        src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg"
                            >
                            <div slot="error" class="image-slot">
                                <i class="el-icon-picture-outline"></i>
                            </div>
                        </el-image>
                        <div>请会员微信扫码支付</div>
                    </div>
                </el-form-item> -->
                <el-form-item label="备注" prop="description">
                    <el-input
                    type="textarea"
                    v-model="form.description"
                    :autosize="{ minRows: 4, maxRows: 7}"
                    placeholder="请输入备注内容"
                    maxlength="140"
                    show-word-limit></el-input>
                </el-form-item>
            </el-form>
            <template slot="footer">
                <el-button class="footer-confirm" @click="sureEvent">{{$t('sure')}}</el-button>
                <el-button class="footer-cancel" @click="closeDialog">{{$t('cancel')}}</el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import apis from "@/apis";
import { mapState } from "vuex";

export default {
    data() {
        return {
            showFlag: false,
            keyword: '',
            form: {
                cardId: '',
                serviceCharge: 0,
                payType: 1,
                payMoney: 0,
                userIdFrom: '',
                userNameFrom: '',
                userIdTo: '',
                userNameTo: '',
                description: '',
                phone: '',
            },
            rules: {
                userIdTo: { required: true, message: '请选择转入会员', trigger: ['blur', 'change'] },
                serviceCharge: { required: true, message: '转卡手续费不能为空', trigger: ['blur', 'change'] },
                payType: { required: true, message: '请选择收款方式', trigger: ['blur', 'change'] },
            },
        };
    },
    computed: {
        ...mapState(['NAME', 'USERNAME', "serviceMemberInfo", "merchantAccountDetailInfoDTO"]),
    },
    methods: {
        init() {
            if (this.$refs.form) {
                this.$refs.form.resetFields();
            }
            this.keyword = ''
            this.form.userIdFrom = this.serviceMemberInfo.userId || ''
            this.form.userNameFrom = this.serviceMemberInfo.userName || ''
        },
        // 弹框打开触发
        showDialog(data) {
            this.init()
            this.form.cardId = data.id || ''
            this.showFlag = true
        },
        querySearchAsync(queryString, cb) {
            this.form.userIdTo = ''
            this.$http.get(`${apis.getUserInfo}?userInfo=${queryString}&merchantId=${this.merchantAccountDetailInfoDTO.merchantId}`).then((res) => {
                if (res.data.code === 0) {
                const dataList = res.data.data.filter((item) => {
                    item.value = item.userName
                    return item.userId !== this.serviceMemberInfo.userId
                })
                cb(dataList);
                }
            })
        },
        handleSelect(item) {
            console.log(item);
            this.form.userIdTo = item.userId
            this.form.userNameTo = item.userName
            this.form.phone = item.phonenumber
        },
        // 确定
        sureEvent() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    const postObj = {
                        ...this.form,
                        operatorName: this.NAME,
                        operatorPhone: this.USERNAME,
                    }
                    this.$http.post(apis.transferUserCard, postObj).then((res) => {
                        if (res.data.code === 0) {
                            this.$emit('refresh')
                            this.closeDialog();
                        }
                    })
                }
            })
        },
        closeDialog() {
            this.init()
            this.$emit('closeDialog', false)
            this.$refs.form.resetFields();
            this.showFlag = false
        },
    },
}
</script>
<style lang='scss' scoped>
.card-fm{width: 150px; height: 150px;}
</style>
