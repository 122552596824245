<template>
    <div class="dialog-container">
        <el-dialog
            title="会员卡退卡"
            :visible.sync="showFlag"
            :before-close="closeDialog"
            center
            :close-on-click-modal="false"
            :append-to-body="true"
            top="10vh"
            width="600px"
            >
            <el-form ref="form" :model="form" class="cancel-form" :rules="rules" label-width="100px">
                <el-form-item label="退款金额" prop="refundMoney">
                    <div class="df ai">
                        <el-input-number
                        v-model="form.refundMoney"
                        class="width-300"
                        controls-position="right"
                        :precision="2"
                        :min="0"
                        :max="999999.99"
                        placeholder="请输入退款金额"
                        ></el-input-number>
                        <div class="margin-left-10">元</div>
                    </div>
                </el-form-item>
                <el-form-item label="退款方式" prop="type">
                    <el-radio-group v-model="form.type">
                        <el-radio-button v-for="item in refundTypeList" :key="item.value" :label="item.value">{{ item.label }}</el-radio-button>
                        <!-- <el-radio-button :label="0">现金</el-radio-button> -->
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="退卡原因" prop="desc">
                    <el-input
                    type="textarea"
                    v-model="form.desc"
                    :autosize="{ minRows: 6, maxRows: 10}"
                    placeholder="请输入退卡原因"
                    maxlength="140"
                    show-word-limit></el-input>
                </el-form-item>
            </el-form>
            <template slot="footer">
                <el-button class="footer-confirm" @click="sureEvent">{{$t('sure')}}</el-button>
                <el-button class="footer-cancel" @click="closeDialog">{{$t('cancel')}}</el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import apis from "@/apis";
import { mapState } from "vuex";
import dictList from "@/utils/dictList.js"

export default {
    data() {
        return {
            showFlag: false,
            form: {
                cardId: '',
                refundMoney: 0,
                type: null, // 1现金 2微信
                desc: '',
                orderNo: '',
            },
            rules: {
                refundMoney: { required: true, message: '退款金额不能为空', trigger: ['blur', 'change'] },
                type: { required: true, message: '请选择付款方式', trigger: ['blur', 'change'] },
                desc: { required: true, message: '请输入退款原因', trigger: ['blur', 'change'] },
            },
            refundTypeList: dictList.REFUND_TYPE || [],
        };
    },
    computed: {
        ...mapState(['NAME', 'USERNAME']),
    },
    methods: {
        init() {
            if (this.$refs.form) {
                this.$refs.form.resetFields()
            }
        },
        // 弹框打开触发
        showDialog(data) {
            this.init()
            this.form.cardId = data.id
            this.form.orderNo = data.orderNo
            this.showFlag = true
        },
        // 确定
        sureEvent() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    const postObj = {
                        ...this.form,
                        operatorName: this.NAME,
                        operatorPhone: this.USERNAME,
                    }
                    this.$http.post(apis.refundUserCard, postObj).then((res) => {
                        if (res.data.code === 0) {
                            // this.$message.success('操作成功')
                            this.$emit('refresh')
                            this.closeDialog();
                        }
                    })
                }
            })
        },
        closeDialog() {
            this.init()
            this.$emit('closeDialog', false)
            this.showFlag = false
        },
    },
}
</script>
<style lang='scss' scoped>
</style>
