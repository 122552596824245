<template>
    <div class="dialog-container">
        <el-dialog
            title="会员制卡"
            :visible.sync="showFlag"
            :before-close="closeDialog"
            center
            :close-on-click-modal="false"
            :append-to-body="true"
            top="10vh"
            width="400px"
            >
                <div class="tip-div df ai">
                    <div class="f-cell"></div>
                    <img class="image" src="@/assets/images/make-icon.png" alt="" srcset="">
                    <div class="text">请将卡片靠近读卡器</div>
                    <div class="f-cell"></div>
                </div>
            <template slot="footer">
                <el-button class="footer-confirm" @click="sureEvent">{{$t('sure')}}</el-button>
                <el-button class="footer-cancel" @click="closeDialog">{{$t('cancel')}}</el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import apis from "@/apis";
import { mapState } from "vuex";

export default {
    data() {
        return {
            showFlag: false,
            form: {
                cardNumber: '12345',
                sportsHobbies: '打球',
                type: 0,
            },
        };
    },
    computed: {
        ...mapState(['NAME', 'USERNAME', "serviceMemberInfo"]),
    },
    methods: {

        // 弹框打开触发
        showDialog() {
            this.showFlag = true
        },
        // 确定
        sureEvent() {
            // this.$refs.form.validate((valid) => {
            //     if (valid) {
            //     }
            // })
            const postObj = {
                ...this.form,
                userId: this.serviceMemberInfo.userId,
                operatorName: this.NAME,
                operatorPhone: this.USERNAME,
            }
            this.$http.post(apis.updateCardNumber, postObj).then((res) => {
                if (res.data.code === 0) {
                    this.$emit('refresh')
                    this.$message.success('操作成功')
                    this.closeDialog();
                }
            })
        },
        closeDialog() {
            this.$emit('closeDialog', false)
            this.showFlag = false
        },
    },
}
</script>
<style lang='scss' scoped>
.tip-div{ margin: 30px auto; text-align: center;
    .image{ width: 36px; height: 36px; display: block; margin-right: 7px;}
    .text{ font-size: 18px; font-weight: 500; color: #404040;}
}

</style>
